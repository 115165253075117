import { cn } from 'src/lib/utils/className';
import LabelBlock from '.';

interface LabelBlockPropsType {
  cols?: number;
  variant?: 'sm' | 'default';
  label: React.ReactNode;
  text: React.ReactNode;
  className?: string;
  labelClassName?: string;
  textClassName?: string;
}

const colsStyle: Record<string, string> = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
};

export default function TextLabelBlock({
  label,
  text,
  cols = 1,
  variant = 'default',
  className,
  labelClassName,
  textClassName,
}: LabelBlockPropsType) {
  return (
    <LabelBlock className={cn(colsStyle[cols], className)}>
      <LabelBlock.Label variant={variant} className={labelClassName}>
        {label}
      </LabelBlock.Label>
      <LabelBlock.Text variant={variant} className={textClassName}>
        {text}
      </LabelBlock.Text>
    </LabelBlock>
  );
}
