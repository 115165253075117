import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';
import { useEffect, useState } from 'react';
import { Calendar } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

export default function DatePicker() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const parseDate = (date: string) => date.split('T')[0] ?? '';

  const selectDateRange = (date: [Date | null, Date | null]) => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set('start_date', parseDate(date[0]?.toISOString() || ''));
    newParams.set('end_date', parseDate(date[1]?.toISOString() || ''));

    setSearchParams(newParams);
    setDateRange(date);
  };

  useEffect(() => {
    const startDate = searchParams.get('start_date');
    const endDate = searchParams.get('end_date');

    setDateRange([
      startDate ? new Date(startDate) : null,
      endDate ? new Date(endDate) : null,
    ]);
  }, [searchParams]);

  return (
    <div className="relative flex items-center w-fit">
      <ReactDatePicker
        locale={ko}
        dateFormat="yyyy-MM-dd"
        placeholderText="모집기간"
        selectsRange={true}
        monthsShown={2}
        startDate={dateRange[0]}
        endDate={dateRange[1]}
        onChange={selectDateRange}
        className=" h-[40px] min-w-full   text-[14px] lg:min-w-[490px] px-[15px] border focus:ring-0 outline-none border-solid border-base-200 rounded-lg bg-white cursor-pointer"
      />
      <div className="absolute right-[17px]">
        <Calendar className="w-4 h-4 text-base-400" />
      </div>
    </div>
  );
}
