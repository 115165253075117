import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../../lib/utils/className';

const WrapperVariants = cva('', {
  variants: {
    variant: {
      default: 'flex flex-col gap-y-1',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const LabelVariants = cva('text-base-700', {
  variants: {
    variant: {
      sm: 'text-sm font-bold',
      default: 'text-base font-semibold',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const TextVariants = cva(
  'whitespace-pre-line  break-all border  flex items-center text-base-700 rounded-md bg-slate-100 border-slate-300',
  {
    variants: {
      variant: {
        sm: 'text-sm p-2 min-h-10',
        default: 'text-base p-3 min-h-12',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

interface LabelBlockPropsType extends VariantProps<typeof WrapperVariants> {
  className?: string;
  children: React.ReactNode;
}

interface LabelType
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof LabelVariants> {
  children: React.ReactNode;
  className?: string;
}

interface TextType
  extends React.HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof TextVariants> {
  children: React.ReactNode;
  className?: string;
}

export const LabelBlock = ({
  className,
  variant,
  children,
}: LabelBlockPropsType) => {
  return (
    <div className={cn(WrapperVariants({ variant, className }))}>
      {children}
    </div>
  );
};

const Label = ({ variant, className, ...props }: LabelType) => {
  return (
    <span className={cn(LabelVariants({ variant }), className)} {...props} />
  );
};

const Text = ({ variant, className, ...props }: TextType) => {
  return <p className={cn(TextVariants({ variant }), className)} {...props} />;
};

LabelBlock.Label = Label;
LabelBlock.Text = Text;

export default LabelBlock;
