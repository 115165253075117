import { Plus } from 'lucide-react';
import { Button } from '../ui/button';
import useModal from 'src/hooks/use-modal';

export default function CreateCompanyButton() {
  const { onOpen } = useModal();

  const handleOpenCreateCompanyModal = () => {
    onOpen({ type: 'company_create' });
  };
  return (
    <Button
      onClick={handleOpenCreateCompanyModal}
      className="flex ml-auto gap-x-1"
    >
      <Plus className="w-4 h-4" />
      교육업체 등록
    </Button>
  );
}
