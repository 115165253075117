import { Route, Routes } from 'react-router-dom';
import useAxiosInterceptor from './hooks/useAxiosInterceptor';
import LoginPage from './pages/login';
import Layout from './layout/layout';
import HomePage from './pages/home';
import Faq from './pages/faq';
import ServiceManagePage from './pages/service';
import SubLayout from './layout/sub-layout';
import {
  CATEGORY_MENU_LIST,
  COMPANY_MENU_LIST,
  SERVICE_MENU_LIST,
} from './lib/constants/navigation-sub';

import { ServiceDetailPage } from './pages/service/detail';
import CompanyListPage from './pages/company';
import CategoryPage from './pages/category';
import TestPage from './pages/test';
import ServiceCreatePage from './pages/service/create';
import { ServiceEditPage } from './pages/service/edit';

const Router = () => {
  useAxiosInterceptor();
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route
          path="service"
          element={<SubLayout menuList={SERVICE_MENU_LIST} />}
        >
          <Route index element={<ServiceManagePage />} />
          <Route path="create" element={<ServiceCreatePage />} />
          <Route path="edit/:id" element={<ServiceEditPage />} />
          <Route path=":id" element={<ServiceDetailPage />} />
        </Route>

        <Route
          path="company"
          element={<SubLayout menuList={COMPANY_MENU_LIST} />}
        >
          <Route index element={<CompanyListPage />} />
        </Route>

        <Route
          path="category"
          element={<SubLayout menuList={CATEGORY_MENU_LIST} />}
        >
          <Route index element={<CategoryPage />} />
        </Route>

        <Route path="test" element={<TestPage />} />

        <Route path="faq" element={<Faq />} />
      </Route>
    </Routes>
  );
};

export default Router;
