import { Service, ServiceListResponse } from 'src/lib/types/service';
import { axiosClient, axiosFormClient } from '../instance';
import { ObjectType } from 'src/lib/types/common';
import { createUrl } from 'src/lib/utils/common';
import { ServiceSubmitValues } from 'src/components/service/use-service-form-controller';

// FIXME : 타입 정의
// FIXME : 에러 처리 방식 수정

// (?) 왜 사용하는지 모르겠음.
const getAllServices = async () => {
  try {
    const res = await axiosClient.get(`admin/services`);
    const data = await res.data.services;
    return data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

const getServices = async (
  queryObject: ObjectType,
): Promise<ServiceListResponse> => {
  try {
    const baseUrl = 'admin/services';
    const url = createUrl(baseUrl, queryObject);

    const res = await axiosClient.get(url);

    return res.data;
  } catch (e: any) {
    console.log('[GET: SERVICE_LIST_ERROR] :', e.message);
    throw new Error('서비스 목록을 불러오는데 실패하였습니다.');
  }
};

const getServiceDetail: (serviceId: string) => Promise<Service> = async (
  serviceId: string,
) => {
  try {
    const response = await axiosClient.get(`admin/services/${serviceId}`);

    return response.data;
  } catch (e) {
    console.log(e);
    throw new Error('서비스를 불러오는데 실패하였습니다.');
  }
};

const deleteService = async (serviceId: string) => {
  try {
    await axiosClient.delete(`admin/services/${serviceId}`);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const addService = async (data: ServiceSubmitValues) => {
  try {
    const response = await axiosFormClient.post(`admin/services`, data);
    return response.data;
  } catch (e: any) {
    if (e.response.data.message === '강의⋅서비스 이름 이름이 중복됩니다.')
      throw new Error('이미 존재하는 강의⋅서비스 이름 이름입니다.');
    else throw new Error(e);
  }
};

const editService = async (serviceId: string, data: ServiceSubmitValues) => {
  try {
    await axiosFormClient.put(`admin/services/${serviceId}`, data);
    return true;
  } catch (e: any) {
    throw new Error(e);
  }
};

const SERVICE_API = {
  getAllServices,
  getServices,
  getServiceDetail,
  deleteService,
  addService,
  editService,
};

export default SERVICE_API;
