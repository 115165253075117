import { useQuery, useQueryClient } from '@tanstack/react-query';
import AUTH_API from '../api/auth';

function useGetUser() {
  return useQuery({
    queryKey: ['user'],
    queryFn: () => AUTH_API.getUser(),
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnMount: false,
  });
}

function usePrefetchUser() {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery({
    queryKey: ['user'],
    queryFn: () => AUTH_API.getUser(),
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

const UserQuery = {
  useGetUser,
  usePrefetchUser,
};

export default UserQuery;
