import type { ComponentProps } from 'react';
import { useEffect, useState } from 'react';
import { cn } from 'src/lib/utils/className';

interface CustomImageProps extends ComponentProps<'img'> {}

// const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

export default function CustomImage(props: CustomImageProps) {
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  useEffect(() => {
    setIsError(false);
  }, [props.src]);

  return (
    <img
      {...props}
      alt={props.alt}
      src={!props.src || isError ? `/default-user.webp` : props.src}
      width={props.width}
      height={props.height}
      style={props.style}
      onError={handleError}
      className={cn('bg-base-100 object-cover', props.className)}
    />
  );
}
