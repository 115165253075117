import { cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'src/lib/utils/className';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: 'sm' | 'default';
}

const inputVariants = cva(
  'flex items-center text-base-700 rounded-md bg-slate-100 border w-full border-slate-300 ring-0 outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-muted-foreground',
  {
    variants: {
      variant: {
        sm: 'text-sm p-2 min-h-10',
        default: 'text-base p-3 min-h-12',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant }), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
