import { Button } from '../ui/button';

interface ReorderingButtonsProps {
  disabled: boolean; // 추가하기 버튼 비활성화 여부
  onReorderStart: () => void; // 정렬 수정 버튼 클릭시 호출되는 함수
  onAddCategory: () => void; // 추가하기 버튼 클릭시 호출되는 함수
}

export default function BeforeReorderingButtons({
  disabled,
  onReorderStart,
  onAddCategory,
}: ReorderingButtonsProps) {
  return (
    <div className="grid grid-cols-2 gap-x-2">
      <Button
        onClick={onReorderStart}
        variant="outline"
        className="h-12 text-base"
      >
        정렬 수정
      </Button>
      <Button
        disabled={disabled}
        onClick={onAddCategory}
        className="h-12 text-base"
      >
        추가하기
      </Button>
    </div>
  );
}
