import {
  Categories,
  Category,
  CategoryType,
  DetailCategory,
  MainCategory,
  SubCategory,
} from 'src/lib/types/category';
import CategoryList from './category-list';
import { useCallback, useEffect, useState } from 'react';

interface CategoryContainerProps {
  category: Categories;
  selectedCategory: {
    mainCategory: MainCategory | null;
    subCategory: SubCategory | null;
    detailCategory: DetailCategory | null;
  };
  onSelectCategory: (type: CategoryType, category: Category) => void;
}

export default function CategoryContainer({
  category,
  selectedCategory,
  onSelectCategory,
}: CategoryContainerProps) {
  const [mockCategory, setMockCategory] = useState<Categories>({
    mainCategories: [],
    subCategories: [],
    detailCategories: [],
  });

  /** 카테고리 초기화 */
  const onInitCategory = useCallback(() => {
    setMockCategory(category);
  }, [category]);

  /** 카테고리 드래그 정렬 */
  const handleReorderCategory =
    (type: 'mainCategories' | 'subCategories' | 'detailCategories') =>
    <T extends MainCategory | SubCategory | DetailCategory>(
      reorderList: T[],
    ) => {
      setMockCategory((prev: Categories) => ({
        ...prev,
        [type]: reorderList.map((item, index) => ({
          ...item,
          position: index,
        })),
      }));
    };

  const updateMainCategoryUI = handleReorderCategory('mainCategories');
  const updateSubCategoryUI = handleReorderCategory('subCategories');
  const updateDetailCategoryUI = handleReorderCategory('detailCategories');

  useEffect(() => {
    onInitCategory();
  }, [onInitCategory]);

  return (
    <>
      <CategoryList
        type={CategoryType.MAIN}
        list={mockCategory.mainCategories}
        parentId={null}
        selectedCategory={selectedCategory.mainCategory}
        selectCategory={onSelectCategory}
        onReorder={updateMainCategoryUI}
        onResetOrder={onInitCategory}
      />
      <CategoryList
        type={CategoryType.SUB}
        list={mockCategory.subCategories}
        parentId={selectedCategory.mainCategory?.id ?? null}
        selectedCategory={selectedCategory.subCategory}
        selectCategory={onSelectCategory}
        onReorder={updateSubCategoryUI}
        onResetOrder={onInitCategory}
      />
      <CategoryList
        type={CategoryType.DETAIL}
        list={mockCategory.detailCategories}
        parentId={selectedCategory.subCategory?.id ?? null}
        selectedCategory={selectedCategory.detailCategory}
        selectCategory={onSelectCategory}
        onReorder={updateDetailCategoryUI}
        onResetOrder={onInitCategory}
      />
    </>
  );
}
