import ContentsSearchBar from 'src/components/common/search-bar/contents-search-bar';
import ServiceFilterList from '../../components/service/service-filter';

import DatePicker from 'src/components/common/date-picker';
import RefreshParamButton from 'src/components/common/refresh-param-button';
import ServiceTable from 'src/components/service/service-table';
import { useSearchParams } from 'react-router-dom';
import ServiceQuery from 'src/services/queries/service';
import PaginationButtons from 'src/components/common/pagination-buttons';

export default function ServiceManagePage() {
  const [searchParams] = useSearchParams();
  const { data } = ServiceQuery.useGetServiceList(searchParams);

  const page = data?.meta.page;
  const length = data?.meta.count;
  const serviceList = data?.serviceList ?? [];

  return (
    <section className="flex flex-col h-full gap-y-4">
      <div className="flex justify-between gap-x-4">
        <div className="flex gap-x-2">
          <RefreshParamButton />
          <DatePicker />
        </div>

        <ContentsSearchBar
          className="min-w-[420px]"
          placeholder="검색어를 입력해주세요."
        />
      </div>

      <ServiceFilterList />
      <div className="w-full rounded-lg shadow-lg">
        <ServiceTable serviceList={serviceList} />
      </div>

      <div className="flex justify-center w-full h-10 ">
        <PaginationButtons length={length} currentPage={page} limit={12} />
      </div>
    </section>
  );
}
