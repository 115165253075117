import { useSearchParams } from 'react-router-dom';
import PaginationButtons from 'src/components/common/pagination-buttons';
import RefreshParamButton from 'src/components/common/refresh-param-button';
import ContentsSearchBar from 'src/components/common/search-bar/contents-search-bar';
import CreateCompanyButton from 'src/components/company/create-company-button';
import CompanyTable from 'src/components/company/company-table';
import CompanyQuery from 'src/services/queries/company';

const CompanyListPage = () => {
  const [searchParams] = useSearchParams();
  const { data } = CompanyQuery.useGetCompanyList(searchParams);

  const page = data?.meta.page;
  const length = data?.meta.count;
  const companyList = data?.companyList ?? [];

  return (
    <section className="flex flex-col h-full gap-y-4">
      <section className="flex gap-x-2">
        <RefreshParamButton />
        <ContentsSearchBar
          className="min-w-[420px]"
          placeholder="검색어를 입력해주세요."
        />
        <CreateCompanyButton />
      </section>
      <CompanyTable companyList={companyList} />
      <div className="flex justify-center w-full h-10 ">
        <PaginationButtons length={length} currentPage={page} limit={12} />
      </div>
    </section>
  );
};

export default CompanyListPage;
