import axios from 'axios';

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosFormClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// FIXME: 403 401 에러에 대한 처리
