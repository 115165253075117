import ServiceDetailHeader from './service-detail-header';
import { ExtendedService } from 'src/lib/types/service';
import ServiceDetailToggle from './service-detail-toggle';
import ServiceDetailEditorViewer from './service-detail-editor-viewer';
import { useSearchParams } from 'react-router-dom';
import ServiceDetailInfo from './service-detail-info';
import useCategory from 'src/hooks/use-category';
import { getCategoryNamesByMapper } from 'src/lib/utils/category';

interface ServiceDetailContainerProps {
  service: ExtendedService;
}
export default function ServiceDetailContainer({
  service,
}: ServiceDetailContainerProps) {
  const { category } = useCategory();

  const [searchParams, setSearchParams] = useSearchParams();

  const categoryNames = getCategoryNamesByMapper(
    category,
    service.categoryMapper,
  );

  const tab = searchParams.get('tab') ?? 'education';

  const handleTabChange = (value: string) => {
    setSearchParams({ tab: value });
  };

  const isEducationTab = tab === 'education';
  const isDetail = tab === 'detail';

  return (
    <div className="flex flex-col w-full h-full text-base-800">
      <ServiceDetailHeader service={service} />
      <ServiceDetailToggle tab={tab} onTabChange={handleTabChange} />

      {isEducationTab && (
        <section className="px-[120px] grid grid-cols-[1fr,330px] gap-x-4 py-[60px] w-full ">
          <ServiceDetailEditorViewer content={service.serviceContent} />
          <img src="/serviceSide.png" alt="serviceSide" />
        </section>
      )}

      {isDetail && (
        <ServiceDetailInfo service={service} categoryNames={categoryNames} />
      )}
    </div>
  );
}
