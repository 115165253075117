import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from '../../ui/dialog';
import { Button } from '../../ui/button';
import { formatDateToISO } from 'src/lib/utils/common';
import TextLabelBlock from '../../common/label-block/text-label-block';
import { Company } from 'src/lib/types/company';
import CustomImage from '../../common/custom-image';
import { ModalOpenProps } from 'src/hooks/use-modal';

interface PropsType {
  company: Company;
  onOpen: (payload: ModalOpenProps) => void;
  onClose: () => void;
}

export const CompanyDetailModal = ({ company, onOpen, onClose }: PropsType) => {
  const handleEditModalOpen = () => {
    onOpen({ type: 'company_edit', data: { company } });
  };

  const handleDeleteModalOpen = () => {
    onOpen({ type: 'company_delete', data: { company } });
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="min-w-[600px] max-h-[700px] overflow-auto custom-scrollbar">
        <DialogHeader></DialogHeader>
        <DialogDescription>
          <div className="flex flex-col items-center mb-12">
            <CustomImage
              src={company.imageUrl}
              alt="profile"
              className="w-[120px] h-[120px] rounded-full border border-base-200"
            />
          </div>

          <div className="grid grid-cols-2 gap-3 ">
            <TextLabelBlock
              variant="sm"
              label="교육 업체"
              text={company.name}
            />
            <TextLabelBlock
              variant="sm"
              label="업체 등록일"
              text={formatDateToISO(company.createdAt)}
            />
            <TextLabelBlock
              variant="sm"
              cols={2}
              label="업체 URL"
              text={
                company.url ? (
                  <a
                    href={company.url}
                    className="text-blue-400 hover:text-blue-500"
                  >
                    {company.url}
                  </a>
                ) : (
                  '입력 없음'
                )
              }
            />

            <TextLabelBlock
              variant="sm"
              cols={2}
              label="업체 설명"
              text={company.description || '입력 없음'}
            />
          </div>
        </DialogDescription>
        <DialogFooter className="flex gap-x-1">
          <Button variant="outline" onClick={handleDeleteModalOpen}>
            삭제하기
          </Button>
          <Button onClick={handleEditModalOpen}>수정하기</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
