'use client';

import { useSearchParams } from 'react-router-dom';
import Select from 'src/components/common/select/select';
import useCategory from 'src/hooks/use-category';
import {
  SERVICE_COST,
  SERVICE_FORM,
  SERVICE_REGION,
  SERVICE_TYPE,
} from 'src/lib/constants/service';
import { convertCategoryToSelectOption } from 'src/lib/utils/category';
import { cn } from 'src/lib/utils/className';

const ServiceFilterList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    mainCategoryList: mainCategories,
    findDetailCategoryListBySubCategoryId,
    findSubCategoryListByMainCategoryId,
  } = useCategory();

  const mainCategory = searchParams.get('main_category');
  const subCategory = searchParams.get('sub_category');
  const itemCategory = searchParams.get('detail_category');
  const serviceType = searchParams.get('service_type');
  const cost = searchParams.get('cost_type');
  const mode = searchParams.get('service_form'); // service_form 변경
  const region = searchParams.get('region');

  const mainCategoryList = convertCategoryToSelectOption(mainCategories);

  const subCategoryList = convertCategoryToSelectOption(
    findSubCategoryListByMainCategoryId(mainCategory),
  );

  const itemCategoryList = convertCategoryToSelectOption(
    findDetailCategoryListBySubCategoryId(subCategory),
  );

  const resetPage = (searchParams: URLSearchParams) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('page');
    return newSearchParams;
  };

  const onSelect = (queryName: string) => (value: string) => {
    const newSearchParams = resetPage(searchParams);

    /**  선택 없음이라면 제거*/
    if (value === 'none') {
      newSearchParams.delete(queryName);
      return setSearchParams(newSearchParams);
    }

    newSearchParams.set(queryName, value);

    /** 메인 선택 시 하위 카테고리 초기화 */
    if (queryName === 'main_category') {
      newSearchParams.delete('sub_category');
      newSearchParams.delete('detail_category');

      /** 서브 카테고리 선택 시 하위 카테고리 초기화 */
    } else if (queryName === 'sub_category') {
      newSearchParams.delete('detail_category');
    }

    setSearchParams(newSearchParams);
  };

  return (
    <div className={cn('flex w-full items-center justify-between gap-x-2 ')}>
      <Select
        placeholder="대 카테고리"
        onSelect={onSelect('main_category')}
        items={mainCategoryList as any}
        value={mainCategory ?? ''}
      />
      <Select
        placeholder="중 카테고리"
        onSelect={onSelect('sub_category')}
        items={subCategoryList as any}
        value={subCategory ?? ''}
      />
      <Select
        placeholder="소 카테고리"
        onSelect={onSelect('detail_category')}
        items={itemCategoryList as any}
        value={itemCategory ?? ''}
      />

      <Select
        placeholder="서비스 유형"
        items={SERVICE_TYPE}
        onSelect={onSelect('service_type')}
        value={serviceType || ''}
      />
      <Select
        placeholder="비용"
        items={SERVICE_COST}
        onSelect={onSelect('cost_type')}
        value={cost || ''}
      />
      <Select
        placeholder="온 · 오프라인"
        items={SERVICE_FORM}
        onSelect={onSelect('service_form')}
        value={mode || ''}
      />
      <Select
        placeholder="지역"
        items={SERVICE_REGION}
        onSelect={onSelect('region')}
        value={region || ''}
      />
    </div>
  );
};

export default ServiceFilterList;
