export default function ReloadIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5233 7.33691C14.3399 7.33691 14.164 7.40715 14.0343 7.53218C13.9046 7.6572 13.8317 7.82677 13.8317 8.00358C13.84 9.25227 13.3962 10.4646 12.5769 11.4319C11.7576 12.3991 10.6141 13.0607 9.34318 13.3027C8.0723 13.5446 6.75368 13.3518 5.61441 12.7574C4.47514 12.1631 3.58655 11.2044 3.10168 10.0464C2.61681 8.88847 2.56601 7.60379 2.95803 6.41363C3.35005 5.22347 4.16036 4.20234 5.24938 3.5261C6.33841 2.84986 7.63798 2.56086 8.92427 2.70885C10.2106 2.85685 11.4031 3.43259 12.2964 4.33691H10.6365C10.4531 4.33691 10.2772 4.40715 10.1475 4.53218C10.0178 4.6572 9.9449 4.82677 9.9449 5.00358C9.9449 5.18039 10.0178 5.34996 10.1475 5.47499C10.2772 5.60001 10.4531 5.67025 10.6365 5.67025H13.7695C13.9529 5.67025 14.1288 5.60001 14.2585 5.47499C14.3882 5.34996 14.4611 5.18039 14.4611 5.00358V2.00358C14.4611 1.82677 14.3882 1.6572 14.2585 1.53218C14.1288 1.40715 13.9529 1.33691 13.7695 1.33691C13.5861 1.33691 13.4102 1.40715 13.2804 1.53218C13.1507 1.6572 13.0779 1.82677 13.0779 2.00358V3.18358C11.9261 2.12229 10.4295 1.47688 8.84111 1.35647C7.25268 1.23605 5.66983 1.64801 4.36013 2.52271C3.05042 3.3974 2.09421 4.68119 1.65311 6.15704C1.21202 7.6329 1.31311 9.2103 1.93929 10.6226C2.56547 12.0349 3.67833 13.1955 5.08975 13.9081C6.50118 14.6208 8.1246 14.8418 9.68558 14.5339C11.2466 14.2259 12.6494 13.4079 13.6568 12.218C14.6643 11.0282 15.2147 9.53946 15.2149 8.00358C15.2149 7.82677 15.1421 7.6572 15.0124 7.53218C14.8827 7.40715 14.7068 7.33691 14.5233 7.33691Z"
        fill="#C8C8C8"
      />
    </svg>
  );
}
