/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable} from '@tanstack/react-table';
import {useEffect, useRef, useState} from 'react';
const AliyTable = ({
                     data,
                     columns,
                     margin,
                     limit = 10,
                     page,
                     setPage,
                     onClick,
                     onClick2,
                     rowSelection,
                     setRowSelection,
                     isResize,
                     total,
                     onClickRow,
                     isNotPageNation,
                     onClickPage,
                 } : any) => {
    const [tableSize, setTableSize] = useState(0);
    const tableRef = useRef(null);
    const [sorting, setSorting] = useState<any>([]);


    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            sorting,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    useEffect(() => {
        table.setPageSize(limit);
    }, [limit]);

    useEffect(() => {
        const sum = columns.reduce((acc:any, column:any) => {
            const {accessorKey, size} = column;
            if (table.getState().columnSizing?.[accessorKey] != null) {
                return acc + table.getState().columnSizing[accessorKey];
            }
            return acc + size;
        }, 0);
        setTableSize(sum);
    }, [table.getState().columnSizing]);

    return (
        <>
            <div>
                <div className={`w-[1100px] ml-[49px]`} ref={tableRef}>
                    <table
                        {...{
                            style: {
                                width:'100%',
                                tableLayout: 'fixed',
                            },
                        }}>
                        <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th
                                        className="border-t border-b-2 border-[#dadee3] h-[50px] bg-[#ffffff] relative overflow-hidden"
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        onClick={header.id.includes('check') || header.id.includes('cancel_state') ? undefined : header.column.getToggleSortingHandler()}
                                        style={{
                                            width: header.getSize(),
                                            position: 'sticky',
                                            top: '0px',
                                        }}
                                    >
                                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                        {header.column.getIsSorted() === 'asc' ? '' : null}
                                        {header.column.getIsSorted() === 'desc' ? '' : null}
                                    </th>

                                ))}
                            </tr>
                        ))}

                        </thead>
                        <tbody className="border-b border-[#dadee3]">
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id} className={`h-[56px] hover:bg-[#f6f9ff] cursor-pointer`}>
                                    {row.getVisibleCells().map(cell => (
                                        <td className="h-[56px] p-5 whitespace-nowrap truncate"
                                            {...{
                                                key: cell.id,
                                                style: {
                                                    width: cell.column.getSize(),
                                                },
                                            }}
                                            onClick={() => {
                                                if (cell.id.includes('check')) return;
                                                if (cell.id.includes('cancel_state')) return;
                                                if (Object.keys(row.original as any).includes('select')) {
                                                    if (cell.id.includes('select')) {
                                                        onClick(row.original);
                                                    } else if (cell.id.includes('onClick2')) {
                                                        if (onClick2) onClick2(row.original);
                                                    } else if (cell.id.includes('registration_type')) {
                                                        onClick(row.original);
                                                    } else {
                                                        if (onClickRow) {
                                                            onClickRow(row.original);
                                                        }
                                                    }
                                                    return;
                                                } else {
                                                    if (onClick) {
                                                        onClick(row.original);
                                                    }
                                                }
                                            }}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default AliyTable;
