import { useParams } from 'react-router-dom';
import ServiceEditForm from 'src/components/service/edit/service-edit-form';

export const ServiceEditPage = () => {
  const { id } = useParams();
  return (
    <section className="flex flex-col w-full h-full gap-y-4 ">
      <h1 className="text-2xl font-semibold text-base-800">강의 서비스 수정</h1>
      <section className="w-full h-full bg-white rounded-lg shadow-md">
        <ServiceEditForm id={id!} />
      </section>
    </section>
  );
};
