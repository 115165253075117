import { useSearchParams } from 'react-router-dom';
import {
  PaginationButtonUI,
  PaginationButtonUIProps,
} from './pagination-buttons-ui';

type PaginationButtonsProps = Omit<PaginationButtonUIProps, 'setPage'> & {};

const PaginationButtons = ({
  length = 0,
  limit = 10,
  currentPage = 1,
  pagesToShow = 10,
}: PaginationButtonsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleNavigateToPage = (page: number) => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set('page', page.toString());

    setSearchParams(newParams);
  };

  return (
    <PaginationButtonUI
      length={length}
      limit={limit}
      currentPage={currentPage}
      pagesToShow={pagesToShow}
      setPage={handleNavigateToPage}
    />
  );
};

export default PaginationButtons;
