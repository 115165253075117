'use client';
import * as SelectPrimitive from '@radix-ui/react-select';
import { cva } from 'class-variance-authority';
import { ChevronDown } from 'lucide-react';
import {
  Select as SelectContainer,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../ui/select';
import { cn } from 'src/lib/utils/className';

// size lg md sm xs

interface SelectProps extends SelectPrimitive.SelectProps {
  placeholder: string;
  items: { name: string; value?: string }[];
  size?: 'sm' | 'md' | 'lg';
  variant?: 'default' | 'outline';
  value: string;
  onSelect: (value: string) => void;
}

const selectTriggerVariants = cva(
  'relative flex w-full min-w-fit gap-x-2 font-medium  pr-8 text-base-500 text-14pxr  bg-white disabled:opacity-50',
  {
    variants: {
      size: {
        sm: 'w-fit h-32pxr text-base-400', // 수정 필요
        md: 'w-116pxr h-32pxr',
        lg: 'w-146pxr h-42pxr',
      },
      variant: {
        default: 'border-none',
        outline: 'border-base-200',
      },
      defaultVariants: {
        size: 'lg',
        variant: 'outline',
      },
    },
  },
);

const Select = ({
  placeholder,
  items,
  value,
  size = 'lg',
  variant = 'outline',
  onSelect,
}: SelectProps) => {
  return (
    <SelectContainer onValueChange={onSelect} value={value}>
      <SelectTrigger
        className={cn(
          selectTriggerVariants({
            size,
            variant,
          }),
        )}
        disabled={items.length === 0}
      >
        <div className="whitespace-nowrap">
          <SelectValue placeholder={placeholder} />
        </div>
        <ChevronDown className="absolute w-4 h-4 right-2" />
      </SelectTrigger>
      <SelectContent className="w-full border shadow-md border-base-200 shadow-base-100 ">
        {items.map(item => (
          <SelectItem
            key={item.value}
            value={item.value as string}
            className={cn(
              'whitespace-nowrap pl-2 text-14pxr focus:bg-base-100 text-base-800',
              // item.value === value && 'bg-base-100',
            )}
          >
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectContainer>
  );
};

export default Select;
