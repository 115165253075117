export const UnregistCourseServiceReq_CATEGORIES = [
    {
     id: 'status', name: 'status' ,
    }
]

export const UnregistReviews_CATEGORIES_One = [
    {
        id: '등록여부', name: '등록여부' ,
    }
]
export const UnregistReviews_CATEGORIES_Two = [
    {
        id: '인증여부', name: '인증여부' ,
    }
]

export const UnregistReviews_isPublic = [
    {
        id: '공개', name: '공개' ,
    },
    {
        id: '비공개', name: '비공개' ,
    }
]


export const Cert_Manage_Categories_IsRegist = [
    {id:'등록 여부', name:'등록 여부'}
]

export const Cert_Manage_Categories_IsAuth = [
    {id:'인증신청 여부', name:'인증신청 여부'}
]
export const Cert_Manage_Categories_IsStatus = [
    {id:'인증 상태', name:'인증 상태'}
]

export const Information_update_IsStatus = [
    {
        id: 'status', name: 'status' ,
    }
]

export const Inquiry_History_IsStatus = [
    {
        id: 'status', name: 'status' ,
    }
]

