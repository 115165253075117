import { useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import SearchBar from '.';

interface ContentsSearchBarProps {
  placeholder?: string;
  className?: string;
}

/** [search] querystring을 변경시키는 서치바 */
export default function ContentsSearchBar({
  placeholder,
  className,
}: ContentsSearchBarProps) {
  const [, setSearchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const onSearchContents = () => {
    if (!inputRef.current) return;

    const keyword = inputRef.current.value;

    if (keyword === '') return setSearchParams({});

    setSearchParams({ search: keyword });
  };

  return (
    <SearchBar
      onSubmit={onSearchContents}
      ref={inputRef}
      placeholder={placeholder}
      className={className}
    />
  );
}
