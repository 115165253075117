export const SERVICE_TYPE = [
  { value: 'none', name: '선택 없음' },
  { value: 'bootcamp', name: '부트캠프' },
  {
    value: 'online_course',
    name: '온라인 강의',
  },
  { value: 'offline_course', name: '오프라인 강의' },
  {
    value: 'membership_subscription',
    name: '멤버십 · 구독',
  },
  { value: 'youtube', name: '유튜브' },
  { value: 'app_payment_service', name: '앱 결제 서비스' },
  { value: 'vvalueeo_call', name: '화상 · 통화' },
  {
    value: 'consulting',
    name: '컨설팅',
  },
  {
    value: 'ebook',
    name: '전자책',
  },
  { value: 'book', name: '도서' },
  { value: 'others', name: '기타' },
];

export const SERVICE_FORM = [
  { value: 'none', name: '선택 없음' },
  { value: 'online', name: '온라인' },
  { value: 'offline', name: '오프라인' },
  { value: 'both', name: '온오프라인' },
];

export const SERVICE_REGION = [
  { value: 'none', name: '선택 없음' },
  { value: 'seoul', name: '서울' },
  { value: 'busan', name: '부산' },
  { value: 'daegu', name: '대구' },
  { value: 'gwangju', name: '광주' },
  { value: 'incheon', name: '인천' },
  { value: 'daejeon', name: '대전' },
  { value: 'ulsan', name: '울산' },
  { value: 'gyeonggi', name: '경기' },
  { value: 'gangwon', name: '강원' },
  { value: 'chungcheongnam', name: '충청남도' },
  { value: 'chungcheongbuk', name: '충청북도' },
  { value: 'jeollanam', name: '전라남도' },
  { value: 'jeollabuk', name: '전라북도' },
  { value: 'gyeongsangnam', name: '경상남도' },
  { value: 'gyeongsangbuk', name: '경상북도' },
  { value: 'jeju', name: '제주' },
];

export const SERVICE_COST = [
  { value: 'none', name: '선택 없음' },
  { value: 'paid', name: '유료' },
  { value: 'government_subsidy', name: '국비지원' },
  { value: 'free', name: '무료' },
];

export const SERVICE_STATUS = [
  { value: 'Applying', name: '신청중', className: 'bg-[#FFE2DD]' },
  { value: 'Proceeding', name: '진행중', className: 'bg-[#FDECC8]' },
  { value: 'Completed', name: '완료됨', className: 'bg-[#DBEDDB]' },
];

export const findServiceTypeName = (value: string) => {
  if (!value) return;
  const serviceType = SERVICE_TYPE.find(service => service.value === value);
  return serviceType?.name;
};

export const findServiceFormName = (value: string) => {
  if (!value) return;
  const serviceForm = SERVICE_FORM.find(service => service.value === value);
  return serviceForm?.name;
};

export const findServiceRegionName = (value: string) => {
  if (!value) return;
  const serviceRegion = SERVICE_REGION.find(service => service.value === value);
  return serviceRegion?.name;
};

export const findServiceCostName = (value: string) => {
  if (!value) return;
  const serviceCost = SERVICE_COST.find(service => service.value === value);
  return serviceCost?.name;
};

export const findServiceStatusName = (value?: string) => {
  if (!value) return;
  const serviceStatus = SERVICE_STATUS.find(status => status.value === value);
  return serviceStatus?.name;
};
