import * as z from 'zod';

export const companyFormSchema = z.object({
  name: z.string().min(1, { message: '교육 업체명을 1자 이상 입력해주세요.' }),
  description: z.string(),
  url: z.string(),
  image: z.union([z.string(), z.instanceof(File)]),
});

export const companyInitialValues = {
  name: '',
  description: '',
  url: '',
  image: '',
};

export type CompanyFormValues = z.infer<typeof companyFormSchema>;
