import { Dialog, DialogContent, DialogHeader } from '../../ui/dialog';
import CompanyQuery from 'src/services/queries/company';
import { Company } from 'src/lib/types/company';
import CustomImage from 'src/components/common/custom-image';
import ContentsSearchBar from 'src/components/common/search-bar/contents-search-bar';
import { useSearchParams } from 'react-router-dom';

interface PropsType {
  onSelect: (company: Company) => void;

  onClose: () => void;
}

export const CompanySearchModal = ({ onSelect, onClose }: PropsType) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = new URLSearchParams([
    ['page', '1'],
    ['limit', '1000'],
    ...searchParams.entries(),
  ]);
  const { data: companyListData } = CompanyQuery.useGetCompanyList(params);

  const companyList = companyListData?.companyList ?? [];

  const handleModalClose = () => {
    setSearchParams({});
    onClose();
  };

  const handleSelect = (company: Company) => {
    onSelect(company);
    handleModalClose();
  };

  return (
    <Dialog open onOpenChange={handleModalClose}>
      <DialogContent className="min-w-[600px] w-full ">
        <DialogHeader className="text-xl font-semibold text-base-800">
          업체 선택
        </DialogHeader>
        <ContentsSearchBar
          className="w-full lg:max-w-full"
          placeholder="업체를 검색해주세요."
        />

        <ul className="flex flex-col border rounded-lg border-base-200 max-h-[400px] h-full overflow-auto custom-scrollbar text-base-600">
          {companyList?.map(company => (
            <li
              key={company.id}
              onClick={() => handleSelect(company)}
              className="flex items-center px-4 py-2 font-medium border-b cursor-pointer gap-x-4 hover:bg-base-100 border-base-200 last:border-b-0"
            >
              <CustomImage
                src={company.imageUrl}
                className="w-8 h-8 border rounded-full border-base-200"
              />
              {company.name}
            </li>
          ))}
        </ul>
      </DialogContent>
    </Dialog>
  );
};
