import { useCallback } from 'react';

import {
  DetailCategory,
  MainCategory,
  SubCategory,
} from 'src/lib/types/category';
import CategoryQuery from 'src/services/queries/category';

const useCategory = () => {
  const { data } = CategoryQuery.useGetCategoryList();

  const category = data as {
    mainCategories: MainCategory[];
    subCategories: SubCategory[];
    detailCategories: DetailCategory[];
  };

  const mainCategoryList = category.mainCategories;

  // id로 mainCategory 찾기
  const findMainCategoryById = (id: string | number) => {
    if (!id) return;

    const category = mainCategoryList.find(
      category => String(category.id) === String(id),
    );
    return category;
  };

  // id로 subCategory 찾기
  const findSubCategoryById = useCallback(
    (id: string | number) => {
      if (!id) return;
      const target = category.subCategories.find(
        category => String(category.id) === String(id),
      );
      return target;
    },
    [category.subCategories],
  );

  // id[]로 detailCategory[] 찾기
  const findDetailCategoryById = useCallback(
    (ids: (string | number)[]): DetailCategory[] => {
      if (!ids?.length) return [];

      const stringIds = ids.map(id => String(id));
      const targetList = category.detailCategories.filter(category =>
        stringIds.includes(String(category.id)),
      );
      return targetList;
    },
    [category.detailCategories],
  );

  // subCategoryId로 detailCategory[] 찾기
  // FIXME: hook에서 분리. category.ts로 이동
  const findDetailCategoryListBySubCategoryId = useCallback(
    (id: string | number | null): DetailCategory[] => {
      if (!id) return [];
      const categoryList = category.detailCategories.filter(
        category => String(category.subCategoryId) === String(id),
      );
      return categoryList;
    },
    [category.detailCategories],
  );

  // mainCategoryId로 subCategory[] 찾기
  const findSubCategoryListByMainCategoryId = useCallback(
    (id: string | null) => {
      if (!id) return [];
      const categories = category.subCategories.filter(
        category => String(category.mainCategoryId) === String(id),
      );
      return categories;
    },
    [category],
  );

  return {
    category,
    mainCategoryList,
    findMainCategoryById,
    findSubCategoryById,
    findDetailCategoryById,
    findDetailCategoryListBySubCategoryId,
    findSubCategoryListByMainCategoryId,
  };
};

export default useCategory;
