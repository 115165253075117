import { cn } from 'src/lib/utils/className';

interface StarIconProps {
  fill?: boolean;
}

export default function StarIcon({ fill = false }: StarIconProps) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.05385 2.63281C7.36524 1.73698 8.63216 1.73698 8.94355 2.63281L9.88683 5.34651C10.0242 5.7417 10.393 6.00965 10.8113 6.01817L13.6837 6.07671C14.6319 6.09603 15.0234 7.30094 14.2676 7.87392L11.9782 9.60961C11.6448 9.86237 11.504 10.2959 11.6251 10.6964L12.4571 13.4463C12.7317 14.354 11.7067 15.0987 10.9282 14.557L8.57005 12.916C8.22663 12.677 7.77076 12.677 7.42734 12.916L5.06915 14.557C4.29067 15.0987 3.26571 14.354 3.54035 13.4463L4.37229 10.6964C4.49344 10.2959 4.35257 9.86237 4.01917 9.60961L1.72978 7.87392C0.974021 7.30094 1.36552 6.09603 2.31373 6.07671L5.1861 6.01817C5.6044 6.00965 5.9732 5.7417 6.11057 5.34651L7.05385 2.63281Z"
        fill={cn(fill ? '#FDDA5B' : '#D7DFE7')}
      />
    </svg>
  );
}
