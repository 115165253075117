import { ServiceDetailModal } from 'src/components/modals/modal/service-detail-modal';
import useModal from '../hooks/use-modal';
import { useNavigate } from 'react-router-dom';
import { ExtendedService } from 'src/lib/types/service';
import { Categories, CategoryType } from 'src/lib/types/category';
import { Company } from 'src/lib/types/company';
import { CompanyDetailModal } from 'src/components/modals/modal/company-detail-modal';
import { CompanyEditModal } from 'src/components/modals/modal/company-edit-modal';
import { CompanyDeleteModal } from 'src/components/modals/modal/company-delete-modal';
import { CompanyCreateModal } from 'src/components/modals/modal/company-create-modal';
import CategoryDeleteModal from 'src/components/modals/modal/category-delete-modal';
import { CategoryCreateModal } from 'src/components/modals/modal/category-create-modal';
import { CompanySearchModal } from 'src/components/modals/modal/company-search.modal';
import ServiceDeleteModal from 'src/components/modals/modal/service-delete-modal';

const RenderModal = ({
  modals,
}: {
  modals: { [key: string]: JSX.Element };
}) => {
  const { type } = useModal();

  if (!type) {
    return null;
  }

  return modals[type] || null;
};

const ModalProvider = () => {
  const navigate = useNavigate();

  const { data, action, onOpen, onClose } = useModal();

  const handleNavigate = (path: string) => navigate(path);

  return (
    <RenderModal
      modals={{
        service_detail: (
          <ServiceDetailModal
            service={data?.service as ExtendedService}
            category={data?.category as Categories}
            onClose={onClose}
            onNavigate={handleNavigate}
          />
        ),
        service_delete: (
          <ServiceDeleteModal
            id={data?.id as string}
            onClose={onClose}
            onNavigate={handleNavigate}
          />
        ),
        company_detail: (
          <CompanyDetailModal
            company={data?.company as Company}
            onOpen={onOpen}
            onClose={onClose}
          />
        ),
        company_create: (
          <CompanyCreateModal onOpen={onOpen} onClose={onClose} />
        ),
        company_edit: (
          <CompanyEditModal
            company={data?.company as Company}
            onOpen={onOpen}
            onClose={onClose}
          />
        ),
        company_delete: (
          <CompanyDeleteModal
            company={data?.company as Company}
            onOpen={onOpen}
            onClose={onClose}
          />
        ),
        company_list: (
          <CompanySearchModal
            onSelect={action?.onSelectCompany as any}
            onClose={onClose}
          />
        ),
        category_create: (
          <CategoryCreateModal
            type={data?.categoryType as CategoryType}
            parentId={data?.id as number}
            onClose={onClose}
          />
        ),
        category_delete: (
          <CategoryDeleteModal
            type={data?.categoryType as CategoryType}
            id={data?.id as number}
            onClose={onClose}
          />
        ),
      }}
    />
  );
};

export default ModalProvider;
