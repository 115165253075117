import StarIcon from './icons/star';

function RatingStars({ rating }: { rating: number }) {
  const fullStars = Math.floor(rating);
  const emptyStars = 5 - fullStars;

  return (
    <ul className="flex">
      {Array(fullStars)
        .fill(1)
        .map((_, index) => (
          <li key={`star-${index}`}>
            <StarIcon fill />
          </li>
        ))}
      {Array(emptyStars)
        .fill(1)
        .map((_, index) => (
          <li key={`empty-${index}`}>
            <StarIcon />
          </li>
        ))}
    </ul>
  );
}
export default RatingStars;
