export const registSwitch = (value: boolean | undefined) => {
    switch (value) {
        case true:
            return (
                <button
                    className="bg-blue-500 text-white text-[14px] rounded-full w-[72px] h-[26px]">
                    등록완료
                </button>
            )
        case false:
            return (
                <button
                    className="bg-[#DA0015] text-white text-[14px] rounded-full w-[72px] h-[26px]">
                    미등록
                </button>
            )
        default:
            return "-";
    }
};

export const authSwitch = (value: string | undefined) => {
    switch (value) {
        case "0":
            return (
                <button
                    className="bg-[#DA0015] text-white text-[14px] rounded-full w-[72px] h-[26px]">
                    미인증
                </button>
            )
        case "1":
            return (
                <button
                    className="bg-blue-500 text-white text-[14px] rounded-full w-[72px] h-[26px]">
                    인증신청
                </button>
            )
        default:
            return "-";
    }
};



export const statusSwitch = (value:number | undefined) => {
    switch (value) {
        case 1:
            return (
                <button
                    className="bg-[#DA0015] text-white text-[14px] rounded-full w-[72px] h-[26px]">
                    등록대기
                </button>
            )
        case 2:
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    등록완료
                </button>
            )
        default:
            return "-";
    }
};

export const authSwitchButton = (value:string | undefined) => {
    switch (value) {
        case "PENDING":
            return (
                <button
                    className="bg-[#DA0015] text-white text-[14px] rounded-full w-[72px] h-[26px]">
                    인증대기
                </button>
            )
        case "ACCEPTED":
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    등록완료
                </button>
            )
        case "APPROVED":
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    등록완료
                </button>
            )
        default:
            return "-";
    }
};

export const statusSwitchButton = (value:string | undefined) => {
    switch (value) {
        case "PENDING":
            return (
                <button
                    className="bg-[#DA0015] text-white text-[14px] rounded-full w-[72px] h-[26px]">
                    등록대기
                </button>
            )
        case "ACCEPTED":
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    등록완료
                </button>
            )
        case "APPROVED":
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    등록완료
                </button>
            )
        case "COMPLETED":
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    등록완료
                </button>
            )
        default:
            return "-";
    }
};

export const registSwitchButton = (value:string | undefined) => {
    switch (value) {
        case "PENDING":
            return (
                <button
                    className="bg-[#DA0015] text-white text-[14px] rounded-full w-[72px] h-[26px]">
                    등록대기
                </button>
            )
        case "REGISTERED":
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    등록완료
                </button>
            )
        case "REJECTED":
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    등록거절
                </button>
            )
        default:
            return "-";
    }
};

export const reviewOpenSwitchButton = (value:string | undefined) => {
    switch (value) {
        case "PUBLIC":
            return (
                <button
                    className="bg-[#E1E1E1] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    공개
                </button>
            )
        case "PERSONAL":
            return (
                <button
                    className="bg-[#DA0015] text-black text-[14px] rounded-full w-[72px] h-[26px]">
                    비공개
                </button>
            )
        default:
            return "-";
    }
};

export const ServiceStatusSwitch = (value:string | null) => {
    switch (value) {
        case "Applying":
            return (
                <div
                    className="flex justify-center text-[#000000] bg-[#FDECC8] text-[14px] items-center w-[59px] h-[26px] rounded-full">
                    진행중
                </div>
            )
        case "Proceeding":
            return (
                <div
                    className="flex justify-center text-[#000000] text-[14px] font-bold items-center w-[59px] h-[26px] rounded-full">
                    신청중
                </div>
            )
        case "Completed":
            return (
                <div
                    className="flex justify-center text-[#000000] text-[14px] font-bold items-center w-[59px] h-[26px] rounded-full">
                    완료됨
                </div>
            )
        default:
            return "-";
    }
}

// 2023-12-03T12:19:24.425Z -> 2023.12.03 형식 변경 함수
export default function convertDateFormat(dateStr:string | undefined) {
    if(!dateStr) return ;
    const date = new Date(dateStr);
    return date.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).replace(/\. /g, '.').replace(/\.$/, '');
}

// 핸드폰 번호 형식 변경 함수 "01012345678" -> "010-1234-5678"
export function formatPhoneNumber(phoneNumber:string | undefined) {
    if(!phoneNumber) return;
    // 전화번호의 첫 3자리, 중간 4자리, 마지막 4자리를 추출하여 형식에 맞게 반환
    return `${phoneNumber?.substring(0, 3)}-${phoneNumber?.substring(3, 7)}-${phoneNumber?.substring(7)}`;
}
