import { cn } from 'src/lib/utils/className';

interface ServiceDetailToggleProps {
  tab: string;
  onTabChange: (value: string) => void;
}

const TOGGLE_ITEMS = [
  { label: '교육정보', value: 'education' },
  { label: '상세 정보', value: 'detail' },
];

export default function ServiceDetailToggle({
  tab,
  onTabChange,
}: ServiceDetailToggleProps) {
  return (
    <section className="px-[120px]  text-base-400 text-lg font-semibold flex gap-x-[20px] border-b border-base-200 ">
      {TOGGLE_ITEMS.map(item => {
        const isMatch = tab === item.value;
        return (
          <button
            key={item.value}
            onClick={() => onTabChange(item.value)}
            className={cn(
              ' hover:text-slate-500 py-[19px] px-2 relative transition-colors',
              isMatch &&
                'text-base-800 pointer-events-none hover:text-base-800',
            )}
          >
            {item.label}
            {isMatch && (
              <span className="absolute bottom-0 left-0 w-full h-1 rounded-sm bg-primary" />
            )}
          </button>
        );
      })}
    </section>
  );
}
