import ServiceCreateForm from 'src/components/service/create/service-create-form';

export default function ServiceCreatePage() {
  return (
    <section className="flex flex-col w-full h-full gap-y-4 ">
      <h1 className="text-2xl font-semibold text-base-800">강의 서비스 등록</h1>
      <section className="w-full h-full bg-white rounded-lg shadow-md">
        <ServiceCreateForm />
      </section>
    </section>
  );
}
