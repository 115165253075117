import { cn } from 'src/lib/utils/className';

type PropsType = React.HTMLProps<HTMLDivElement> & {
  variants?: keyof typeof SPINNER_VARIANTS;
  className?: string;
};

// border 배경 색상

// border-t가 바의 색상

const DEFAULT_STYLE =
  'border-[rgba(255,255,255,0.4)] rounded-full animate-spin border-t-white';

const SPINNER_VARIANTS = {
  '2xs': 'border-[2px]',
  xs: 'border-[3px] ',
  sm: 'border-[4px] ',
  md: 'border-[5px] ',
  lg: 'border-[6px] ',
};

const SPINNER_SIZE = {
  '2xs': 'w-4 h-4',
  xs: 'w-6 h-6',
  sm: 'w-8 h-8',
  md: 'w-10 h-10',
  lg: 'w-12 h-12',
};

export default function Spinner({
  variants = 'sm',
  className,
  ...props
}: PropsType) {
  return (
    <div
      className={cn(
        DEFAULT_STYLE,
        SPINNER_VARIANTS[variants],
        SPINNER_SIZE[variants],
        className,
      )}
      {...props}
    />
  );
}
