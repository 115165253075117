import CustomImage from 'src/components/common/custom-image';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table';
import useModal from 'src/hooks/use-modal';
import { COMPANY_TABLE_HEADER } from 'src/lib/constants/table';
import { Company } from 'src/lib/types/company';
import { formatDateToISO } from 'src/lib/utils/common';

interface CompanyTableProps {
  companyList: Company[];
  rowsPerPage?: number;
}

export default function CompanyTable({
  companyList,
  rowsPerPage = 10,
}: CompanyTableProps) {
  const { onOpen } = useModal();
  const emptyRows = rowsPerPage - companyList.length;

  const handleRowClick = (company: Company) => {
    onOpen({
      type: 'company_detail',
      data: {
        company,
      },
    });
  };
  return (
    <Table className="bg-white rounded-lg min-w-[1200px] w-full max-w-[1420px]">
      <TableHeader>
        <TableRow>
          {COMPANY_TABLE_HEADER.map(item => (
            <TableHead
              key={item.label}
              style={{ width: item.width, textAlign: item.position }}
            >
              {item.label}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {companyList.map(company => {
          return (
            <TableRow
              key={company.id}
              onClick={() => handleRowClick(company)}
              className="cursor-pointer"
            >
              <TableCell className="">
                <CustomImage
                  src={company.imageUrl}
                  className="w-8 h-8 mx-auto rounded-full"
                  alt="profile"
                />
              </TableCell>
              <TableCell className="flex items-center justify-center px-2 text-center h-[68px]">
                {company.name}
              </TableCell>
              <TableCell className="text-center  h-[68px]   truncate max-w-[400px]">
                {company.description || '-'}
              </TableCell>
              <TableCell className="text-center">
                {formatDateToISO(company.createdAt)}
              </TableCell>
            </TableRow>
          );
        })}

        {Array.from({ length: emptyRows }).map((_, index) => (
          <TableRow key={`empty-row-${index}`}>
            <TableCell className="w-[70px] text-center"></TableCell>
            <TableCell className="flex items-center justify-center px-2 text-center h-[68px]"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
