import { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

export default function TestPage() {
  return <Editor />;
}

const Editor = () => {
  const [editorHtml, setEditorHtml] = useState('');

  const handleChange = (html: string) => {
    setEditorHtml(html);
  };

  const modules = {
    imageActions: {},
    imageFormats: {},
    toolbar: [
      [{ header: '1' }, { header: '2' }, { header: '3' }],
      [{ size: [] }],
      [{ color: [] }, { background: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
    // 핸들러 설정
    // handlers: {
    // image: imageHandler, // 이미지 tool 사용에 대한 핸들러 설정
    // },

    // 이미지 크기 조절
    // ImageResize: {
    //   modules: ['Resize'],
    // },
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'background',
    'align',
    'float',
    'height',
    'width',
    // 'float',
    // 'height',
    // 'width',
  ];

  return (
    <div>
      <ReactQuill
        value={editorHtml}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        theme="snow"
      />
    </div>
  );
};
