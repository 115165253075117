import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from '../../ui/dialog';

import { Company } from 'src/lib/types/company';

import { ModalOpenProps } from 'src/hooks/use-modal';

import CompanyQuery from 'src/services/queries/company';
import { Button } from 'src/components/ui/button';
import { X } from 'lucide-react';

interface PropsType {
  company: Company;
  onOpen: (payload: ModalOpenProps) => void;
  onClose: () => void;
}

export const CompanyDeleteModal = ({ company, onOpen, onClose }: PropsType) => {
  const { mutate: deleteCompany, isPending } = CompanyQuery.useRemoveCompany(
    String(company.id),
  );

  const handlePrevious = () => {
    onOpen({ type: 'company_detail', data: { company } });
  };

  const handleDeleteCompany = () => {
    deleteCompany(undefined, {
      onSuccess: () => {
        alert('교육 업체 삭제가 완료되었습니다.');
        onClose();
      },
      onError: () => {
        alert('교육 업체 삭제에 실패하였습니다.');
      },
    });
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="min-w-[600px] max-h-[700px] overflow-auto custom-scrollbar">
        <DialogHeader></DialogHeader>
        <DialogDescription className="flex flex-col items-center w-full py-8 text-base text-center gap-y-8">
          <div className="w-16 h-16 border-primary rounded-full border-[5px] flex items-center justify-center">
            <X className="w-10 h-10 text-primary" />
          </div>
          <p className="flex flex-col text-lg gap-y-2">
            <strong className="text-xl">{company.name}</strong>
            업체를 삭제하시겠습니까?
          </p>
        </DialogDescription>
        <DialogFooter className="flex gap-x-1">
          <Button variant="outline" onClick={handlePrevious}>
            이전으로
          </Button>
          <Button
            isLoading={isPending}
            disabled={isPending}
            onClick={handleDeleteCompany}
          >
            삭제하기
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
