import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HttpErrorCode } from 'src/lib/types/http';
import { axiosClient } from 'src/services/instance';

const useAxiosInterceptor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const errorHandler = (error: any) => {
    const statusCode = `${error.statusCode}`;

    if (
      location.pathname !== '/login' &&
      (statusCode === HttpErrorCode.UNAUTHORIZED ||
        statusCode === HttpErrorCode.FORBIDDEN)
    ) {
      queryClient.removeQueries({
        queryKey: ['user'],
      });

      navigate('/login', { state: { from: location.pathname } });
    }

    return Promise.reject(error);
  };

  const responseInterceptor = axiosClient.interceptors.response.use(
    (response: any) => response,
    (error: any) => errorHandler(error.response.data),
  );

  useEffect(() => {
    return () => {
      axiosClient.interceptors.response.eject(responseInterceptor);
    };
  }, [responseInterceptor]);
};

export default useAxiosInterceptor;
