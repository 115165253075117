import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';
import './viewer.style.css';
interface ServiceContentProps {
  content: string;
}

export default function ServiceDetailEditorViewer({
  content,
}: ServiceContentProps) {
  return (
    <section className="w-full editor-viewer">
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content),
        }}
      />
    </section>
  );
}
