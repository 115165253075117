import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import Category_API from '../api/category';
import {
  Category,
  CategoryType,
  DetailCategory,
  MainCategory,
  SubCategory,
} from 'src/lib/types/category';

/** 카테고리 목록 조회 */
function useGetCategoryList() {
  return useQuery({
    queryKey: ['category'],
    queryFn: () => Category_API.getAllCategories(),
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnMount: false,
    placeholderData: {
      mainCategories: [],
      subCategories: [],
      detailCategories: [],
    },
  });
}

/** 카테고리 추가 */
function useAddCategory() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['category'],
    mutationFn: ({
      type,
      parentId,
      name,
    }: {
      type: CategoryType;
      parentId: number | null;
      name: string;
    }) => Category_API.addCategory(type, parentId, name),
    onSuccess: () => alert('추가가 완료되었습니다.'),
    onError: () => alert('추가에 실패했습니다.'),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['category'] });
    },
  });
}

/** 카테고리 이름 수정 */
function useUpdateCategoryName() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['category'],
    mutationFn: ({
      type,
      id,
      name,
    }: {
      type: CategoryType;
      id: number;
      name: string;
    }) => Category_API.updateCategoryName(type, id, name),
    onSuccess: () => alert('수정이 완료되었습니다.'),
    onError: () => alert('수정에 실패했습니다.'),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['category'] });
    },
  });
}

/** 카테고리 삭제 */
function useRemoveCategory() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['category'],
    mutationFn: ({ type, id }: { type: CategoryType; id: number }) =>
      Category_API.removeCategory(type, id),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['category'] });
    },
  });
}

/** 카테고리 정렬 */
function useUpdateCategorySorting() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['category'],
    mutationFn: ({ type, list }: { type: CategoryType; list: Category[] }) =>
      Category_API.updateCategorySorting(type, list),
    onSuccess: () => alert('정렬이 완료되었습니다.'),
    onError: () => alert('정렬에 실패했습니다.'),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['category'] });
    },
  });
}

const setMainCategoryUI =
  (queryClient: QueryClient) => (reorderList: MainCategory[]) => {
    queryClient.setQueryData(
      ['category'],
      (prev: {
        mainCategories: MainCategory[];
        subCategories: SubCategory[];
        detailCategories: DetailCategory[];
      }) => {
        return {
          ...prev,
          mainCategories: reorderList,
        };
      },
    );
  };

const setSubCategoryUI =
  (queryClient: QueryClient) => (reorderedList: SubCategory[]) => {
    queryClient.setQueryData(
      ['category'],
      (prev: {
        mainCategories: MainCategory[];
        subCategories: SubCategory[];
        detailCategories: DetailCategory[];
      }) => {
        return {
          ...prev,
          subCategories: prev.subCategories.map(item => {
            const targetIndex = reorderedList.findIndex(
              sub => sub.id === item.id,
            );
            return targetIndex === -1
              ? item
              : { ...item, position: reorderedList[targetIndex].position };
          }),
        };
      },
    );
  };

const setDetailCategoryUI =
  (queryClient: QueryClient) => (reorderedList: DetailCategory[]) => {
    queryClient.setQueryData(
      ['category'],
      (prev: {
        mainCategories: MainCategory[];
        subCategories: SubCategory[];
        detailCategories: DetailCategory[];
      }) => ({
        ...prev,
        detailCategories: prev.detailCategories.map(item => {
          const targetIndex = reorderedList.findIndex(
            detail => detail.id === item.id,
          );
          return targetIndex === -1
            ? item
            : { ...item, position: reorderedList[targetIndex].position };
        }),
      }),
    );
  };

const CategoryQuery = {
  useGetCategoryList,
  useAddCategory,
  useUpdateCategoryName,
  useRemoveCategory,
  useUpdateCategorySorting,
  setMainCategoryUI,
  setSubCategoryUI,
  setDetailCategoryUI,
};
export default CategoryQuery;
