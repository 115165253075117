import { Pencil, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { Category, CategoryType } from 'src/lib/types/category';
import { cn } from 'src/lib/utils/className';
import CategoryForm from './category-form';

type PropsType = {
  type: CategoryType;
  category: Category;
  isSelected: boolean;
  disabled?: boolean; // 정렬중인경우에는 삭제, 수정 불가능
  onSelectCategory: (type: CategoryType, category: Category) => void;
  onUpdateCategory: (id: number, name: string) => void;
  onDeleteCategory: (id: number) => void;
};

const CategoryItem = ({
  type,
  isSelected,
  category,
  disabled,
  onSelectCategory,
  onUpdateCategory,
  onDeleteCategory,
}: PropsType) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleOpen = () => setIsEdit(true);
  const handleClose = () => setIsEdit(false);

  /** 카테고리 삭제 모달 */
  const handleDeleteCategory = async (e: any) => {
    e.stopPropagation();
    onDeleteCategory(category.id);
  };

  return (
    <div
      onClick={e => {
        e.stopPropagation();
        !disabled && onSelectCategory(type, category);
      }}
      className={cn(
        'group flex justify-between items-center border border-base-200 px-4 text-base-700 py-3 text-[16px] rounded-md cursor-pointer bg-slate-50',
        isSelected && 'bg-base-400 text-white ',
      )}
    >
      {!isEdit && <div className="items-center h-full">{category.name}</div>}
      {isEdit && (
        <CategoryForm
          onClose={handleClose}
          onUpdateCategory={onUpdateCategory}
          category={category}
        />
      )}
      {!isEdit && (
        <div className="flex items-center gap-x-2">
          <button
            disabled={disabled}
            onClick={handleOpen}
            className="p-2 transition bg-white border rounded-md text-base-400 border-base-300 hover:bg-base-200 bg-base-200 "
          >
            <Pencil className="w-4 h-4 " />
          </button>

          <button
            disabled={disabled}
            onClick={handleDeleteCategory}
            className="p-2 transition bg-white border rounded-md text-base-400 border-zinc-300 hover:bg-base-200 "
          >
            <Trash2 className="w-4 h-4 " />
          </button>
        </div>
      )}
    </div>
  );
};

export default CategoryItem;
