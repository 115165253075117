import { Company, CompanyResponse } from 'src/lib/types/company';
import { axiosClient, axiosFormClient } from '../instance';
import { ObjectType } from 'src/lib/types/common';
import { createUrl } from 'src/lib/utils/common';

const getAllCompanyList = async (
  queryObject: ObjectType,
): Promise<CompanyResponse> => {
  try {
    const baseUrl = 'company/admin/list';
    const url = createUrl(baseUrl, queryObject);
    const response = await axiosClient.get(`${url}`);

    return response.data;
  } catch (e: any) {
    console.error('[GET: COMPANY_LIST_ERROR]', e);
    throw new Error(e.message);
  }
};

const getCompany = async (id: string): Promise<Company | null> => {
  try {
    const response = await axiosClient.get(`company/${id}`);
    return response.data;
  } catch (e: any) {
    console.error('[GET: COMPANY_ERROR]', e);
    return null;
  }
};

const addCompany = async (data: any) => {
  try {
    const response = await axiosFormClient.post(`company`, data);
    return response.data;
  } catch (e: any) {
    console.error('[ADD: COMPANY_ERROR]', e);
    throw Error(e);
  }
};

const updateCompany = async (id: string, data: any) => {
  try {
    const response = await axiosFormClient.put(`company/${id}`, data);
    return response.data;
  } catch (e: any) {
    console.error('[UPDATE: COMPANY_ERROR]', e);
    throw Error(e?.message || '교육 업체 수정이 실패하였습니다.');
  }
};

const removeCompany = async (id: string) => {
  try {
    await axiosClient.delete(`company/${id}`);
  } catch (e: any) {
    console.error('[DELETE: COMPANY_ERROR]', e);
    throw Error(e?.message || '교육 업체 삭제에 실패하였습니다.');
  }
};

const Company_API = {
  addCompany,
  updateCompany,
  getAllCompanyList,
  getCompany,
  removeCompany,
};

export default Company_API;
