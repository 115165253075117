import { createColumnHelper } from '@tanstack/react-table';
import SmallArrowDrop from '../../icons/small-arrow-drop';
import convertDateFormat, {
  formatPhoneNumber,
  statusSwitchButton,
} from 'src/components/utils/utils';

const columnHelper = createColumnHelper();
export const InquiryHistoryColumn = (detailModalOpen: any) => [
  // Todo : 필터링작업 진행 해야함(헤더의 화살표 / all Column 동일)
  columnHelper.accessor('check', {
    header: () => (
      <div className="flex justify-center text-[#545D69] text-[14px] font-normal">
        <input type="checkbox" className="basis-[4%]" />
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || <input type="checkbox" className="basis-[4%]" />}
      </div>
    ),
    size: 58,
  }),
  columnHelper.accessor('id', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        문의 id
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 89,
  }),
  columnHelper.accessor('name', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        이름
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 113,
  }),
  columnHelper.accessor('phone', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        연락처
        <div className="items-center pl-[10px]">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center pl-[10px] text-center text-[#545D69] text-[14px] font-normal">
        {formatPhoneNumber(info.renderValue()) || '-'}
      </div>
    ),
    size: 244,
  }),
  columnHelper.accessor('email', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        이메일
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 91,
  }),
  columnHelper.accessor('content', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        문의 사항
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 139,
  }),
  columnHelper.accessor('createdAt', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        생성일
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {convertDateFormat(info.renderValue()) || '-'}
      </div>
    ),
    size: 115,
  }),
  columnHelper.accessor('status', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        Status
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {statusSwitchButton(info.renderValue()) || '-'}
      </div>
    ),
    size: 107,
  }),
  columnHelper.accessor('cncel_note', {
    header: () => (
      <div className="flex justify-center text-[#545D69] text-[14px] font-normal">
        조회
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center">
        <button
          onClick={() => detailModalOpen(info.row.original)}
          className="text-blue-600 hover:text-blue-800 w-[72px] h-[26px]"
        >
          상세보기
        </button>
      </div>
    ),
    size: 126,
  }),
];
