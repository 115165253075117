// FIXME : 타입 정의

import { axiosClient } from '../instance';

const login = async (data: any) => {
  const response = await axiosClient.post(`auth/admin/login`, data);
  return response.data;
};

const getUser = async () => {
  try {
    const response = await axiosClient.get('users/info');
    return response.data;
  } catch (e) {
    console.log('[USER INFO ERROR]', e);
    return null;
  }
};

const AUTH_API = { login, getUser };

export default AUTH_API;
