import { createColumnHelper } from '@tanstack/react-table';
import SmallArrowDrop from '../../icons/small-arrow-drop';
import convertDateFormat, {
  statusSwitchButton,
} from 'src/components/utils/utils';

const columnHelper = createColumnHelper();
export const InformationUpdateReqColumn = (detailModalOpen: any) => [
  // Todo : 필터링작업 진행 해야함(헤더의 화살표 / all Column 동일)
  columnHelper.accessor('check', {
    header: () => (
      <div className="flex justify-center text-[#545D69] text-[14px] font-normal">
        <input type="checkbox" className="basis-[4%]" />
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || <input type="checkbox" className="basis-[4%]" />}
      </div>
    ),
    size: 58,
  }),
  // 문의 ID를 id로 , 유저 ID를 userId로 입력해서 처리했음. serviceId는 모르겠음.
  // 문의유형 response에 없음.
  columnHelper.accessor('id', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        문의 id
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 99,
  }),
  columnHelper.accessor('inquiryType', {
    header: () => (
      <div className="flex justify-center items-center  text-[#545D69] text-[14px] font-normal">
        문의 유형
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center items-center text-[#545D69] text-[14px] font-normal">
        정보 수정
      </div>
    ),
    size: 126,
  }),
  columnHelper.accessor('userId', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        유저 id
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 115,
  }),
  columnHelper.accessor('service_serviceName', {
    header: () => (
      <div className="flex justify-between pl-[10px] items-center text-[#545D69] text-[14px] font-normal">
        강의⋅서비스 명
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex flex-start pl-[10px] text-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 324,
  }),
  columnHelper.accessor('createdAt', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        생성일
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {convertDateFormat(info.renderValue()) || '-'}
      </div>
    ),
    size: 115,
  }),
  columnHelper.accessor('status', {
    header: () => (
      <div className="flex justify-center items-center text-[#545D69] text-[14px] font-normal">
        Status
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#545D69] text-[14px] font-normal">
        {statusSwitchButton(info.renderValue()) || '-'}
      </div>
    ),
    size: 107,
  }),
  columnHelper.accessor('cncel_note', {
    header: () => (
      <div className="flex justify-center text-[#545D69] text-[14px] font-normal">
        조회
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center">
        <button
          onClick={() => detailModalOpen(info.row.original)}
          className="text-blue-600 hover:text-blue-800 w-[72px] h-[26px]"
        >
          상세보기
        </button>
      </div>
    ),
    size: 126,
  }),
];
