import { useState } from 'react';
import CategoryContainer from 'src/components/category/category-container';
import useCategory from 'src/hooks/use-category';
import { Category, CategoryType } from 'src/lib/types/category';

const CategoryPage = () => {
  const {
    category,
    findMainCategoryById,
    findSubCategoryById,
    findDetailCategoryById,
    findSubCategoryListByMainCategoryId,
    findDetailCategoryListBySubCategoryId,
  } = useCategory();

  const [selectedCategoryId, setSelectedCategoryId] = useState<
    Record<string, string | null>
  >({
    main: null,
    sub: null,
    detail: null,
  });

  const mainCategoryId = selectedCategoryId.main;
  const subCategoryId = selectedCategoryId.sub;
  const detailCategoryId = selectedCategoryId.detail;

  /** 대 중 소 카테고리  */
  const mainCategories = category.mainCategories;
  const subCategories = findSubCategoryListByMainCategoryId(mainCategoryId);
  const detailCategories = findDetailCategoryListBySubCategoryId(subCategoryId);

  /** 선택된 카테고리 */
  const selectedMainCategory = mainCategoryId
    ? findMainCategoryById(mainCategoryId)
    : null;
  const selectedSubCategory = subCategoryId
    ? findSubCategoryById(subCategoryId)
    : null;
  const selectedDetailCategory = detailCategoryId
    ? findDetailCategoryById([detailCategoryId])?.[0]
    : null;

  const selectedCategory = {
    mainCategory: selectedMainCategory ?? null,
    subCategory: selectedSubCategory ?? null,
    detailCategory: selectedDetailCategory ?? null,
  };
  const showCategories = {
    mainCategories,
    subCategories,
    detailCategories,
  };

  /** 카테고리 선택 기능 */
  const handleSelectCategory = (type: CategoryType, category: Category) => {
    if (type === 'main') {
      setSelectedCategoryId({
        main: String(category.id),
        sub: null,
        detail: null,
      });
    }

    if (type === 'sub') {
      setSelectedCategoryId({
        main: selectedCategoryId.main,
        sub: String(category.id),
        detail: null,
      });
    }

    if (type === 'detail') {
      setSelectedCategoryId({
        main: selectedCategoryId.main,
        sub: selectedCategoryId.sub,
        detail: String(category.id),
      });
    }
  };

  return (
    <main className="flex flex-col items-center justify-center flex-1 w-full h-full py-20 xl:flex-row gap-x-4 gap-y-12">
      <CategoryContainer
        category={showCategories}
        selectedCategory={selectedCategory}
        onSelectCategory={handleSelectCategory}
      />
    </main>
  );
};

export default CategoryPage;
