import { ExtendedService, Service } from '../types/service';

/** Service에 main/sub/detail id 주입 */
export const injectedCategoryIdInService = (
  service: Service,
): ExtendedService => {
  const { categoryMapper } = service;

  const mainCategoryId = categoryMapper[0].mainCategoryId;
  const subCategoryId = categoryMapper[0].subCategoryId;
  const detailCategoryIds = categoryMapper.map(
    category => category.detailCategoryId,
  );

  return {
    ...service,
    mainCategoryId,
    subCategoryId,
    detailCategoryIds,
  };
};
