interface SmallArrowDropProps {
  color: string;
  transform?: string;
}

export default function SmallArrowDrop({
  color,
  transform,
}: SmallArrowDropProps) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      transform={transform}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ant-design:caret-down-filled">
        <path
          id="Vector"
          d="M2.67267 4.01562H9.32767L6.00017 7.87407L2.67267 4.01562ZM6.16201 8.06174L6.16181 8.0615C6.16188 8.06158 6.16195 8.06166 6.16202 8.06174L6.00131 8.20033L6.16201 8.06174Z"
          fill="#EAEAEA"
          stroke="#EAEAEA"
        />
      </g>
    </svg>
  );
}
