import {
  Categories,
  DetailCategory,
  MainCategory,
  SubCategory,
} from '../types/category';
import { CategoryMapper } from '../types/service';

export const makeReorderedCategoryList = (
  categoryList: (MainCategory | SubCategory | DetailCategory)[],
) => {
  return categoryList
    .map((category, index) => {
      return { ...category, position: index };
    })
    .sort((a, b) => a.position - b.position);
};

/** {name,value}[]로 변환 */
export const convertCategoryToSelectOption = (
  arr: MainCategory[] | SubCategory[] | DetailCategory[],
) => {
  return arr.map(category => ({
    name: category.name,
    value: category.id + '',
  }));
};

const getCategoryIdsByMapper = (categoryMapper: CategoryMapper[]) => {
  const firstItem = categoryMapper[0];

  const mainCategoryId = firstItem.mainCategoryId;
  const subCategoryId = firstItem.subCategoryId;
  const detailCategoryIds = categoryMapper.map(item => item.detailCategoryId);

  return { mainCategoryId, subCategoryId, detailCategoryIds };
};

/** 카테고리 맵퍼로부터 카테고리 이름을 가져옴 */
export const getCategoryNamesByMapper = (
  category: Categories,
  categoryMapper: CategoryMapper[],
) => {
  const { mainCategories, subCategories, detailCategories } = category;

  const { mainCategoryId, subCategoryId, detailCategoryIds } =
    getCategoryIdsByMapper(categoryMapper);

  const mainCategoryName =
    mainCategories.find(item => String(item.id) === String(mainCategoryId))
      ?.name ?? '유효하지 않은 카테고리';

  const subCategoryName =
    subCategories.find(item => String(item.id) === String(subCategoryId))
      ?.name ?? '유효하지 않은 카테고리';

  const detailCategoryName = detailCategoryIds
    .map(
      id =>
        detailCategories.find(item => String(item.id) === String(id))?.name ??
        '유효하지 않은 카테고리',
    )
    .join(', ');

  return { mainCategoryName, subCategoryName, detailCategoryName };
};
