import { useEffect, useState } from 'react';
import InputMaster from '../common/input-master';
import ReloadIcon from '../common/icons/reload';
import { Information_update_IsStatus } from '../../lib/constants/options';
import AliyTable from '../common/table/AllyTable';

// import InformationUpdateDetailModal from '../modal/faq/InformationUpdate/information-update-detail-modal';
import FAQ_API from '../../services/api/faq';
import { InformationUpdateRequest } from '../../lib/types/faq';

import { InformationUpdateReqColumn } from '../common/table-columns/faq/information-update-req-column';

const InformationUpdateReq = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [tableData, setTableData] = useState<InformationUpdateRequest[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState('1');
  const limit = '10';

  const [data, setData] = useState({
    status: '',
  });
  const onChangeData = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value } = e.currentTarget;
    setData({ ...data, [name]: value });
  };

  const detailModalOpen = (value: any) => {
    setModalData(value);
    setIsOpen(true);
  };

  const getInformationUpdate = async (page: string, limit: string) => {
    try {
      const response = await FAQ_API.getInformationUpdateReq(page, limit);
      console.log('정보수정', response.count);
      if (response && response.data) {
        setTableData(response.data);
        setTotal(response.count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getInformationUpdate(page, limit);
  }, [page, limit]);

  // getInformationUpdateReq

  return (
    <>
      {/* Search area*/}
      <div className="mt-[40px] mx-[50px]">
        <InputMaster onChange={onChangeData} isSearch placeholder="Search..." />
      </div>
      <div className="flex flex-col gap-y-[17px] mb-9 mt-[33px] ml-[49px]">
        <div className="flex items-center gap-x-[20px]">
          <div className="flex gap-x-[8px] text-[14px] text-[#484848]">
            <ReloadIcon />
            <div className="whitespace-nowrap">필터 초기화</div>
          </div>
          <div className="flex flex-wrap gap-[15px]">
            <select
              className="w-[150px] text-[14px] text-[#484848] text-center font-bold py-[5px] border-2 border-solid border-[#E1E1E1] rounded cursor-pointer"
              value={data.status}
              onChange={e => onChangeData(e)}
              name="status"
            >
              {Information_update_IsStatus.map(option => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
      <AliyTable
        data={tableData}
        columns={InformationUpdateReqColumn(detailModalOpen)}
        limit={limit}
        page={page}
        setPage={setPage}
      />
      {/* <PaginationButtons
        setPage={setPage}
        total={total}
        page={+page || 1}
        limit={+limit || 10}
      /> */}

      {/* {isOpen && (
        <InformationUpdateDetailModal
          tableData={tableData}
          setTableData={setTableData}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          modalData={modalData}
        />
      )} */}
    </>
  );
};

export default InformationUpdateReq;
