export const SERVICE_TABLE_HEADER = [
  { label: '이미지', position: 'center', width: '70px' },
  { label: '강의 ⋅ 서비스명', position: 'center', width: '200px' },
  { label: '서비스 유형', position: 'center', width: 'fit-content' },
  { label: '대 카테고리', position: 'center', width: 'fit-content' },
  { label: '중 카테고리', position: 'center', width: 'fit-content' },
  { label: '소 카테고리', position: 'center', width: 'fit-content' },
  { label: '비용', position: 'center', width: 'fit-content' },
  { label: '모집 종료일', position: 'center', width: 'fit-content' },
  { label: '상태', position: 'center', width: 'fit-content' },
] as const;

export const COMPANY_TABLE_HEADER = [
  { label: '이미지', position: 'center', width: '100px' },
  { label: '업체 이름', position: 'center', width: '400px' },
  { label: '업체 설명', position: 'center', width: '500px' },
  { label: '등록일', position: 'center', width: '300px' },
] as const;
