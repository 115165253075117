import TextLabelBlock from 'src/components/common/label-block/text-label-block';
import {
  findServiceCostName,
  findServiceFormName,
  findServiceRegionName,
  findServiceStatusName,
  findServiceTypeName,
} from 'src/lib/constants/service';
import { CategoryNames } from 'src/lib/types/category';
import { ExtendedService } from 'src/lib/types/service';
import { formatDateToISO, formatNumberWithCommas } from 'src/lib/utils/common';
import ServiceDetailEditorViewer from './service-detail-editor-viewer';
import CustomImage from 'src/components/common/custom-image';

interface ServiceDetailInfoProps {
  service: ExtendedService;
  categoryNames: CategoryNames;
}

export default function ServiceDetailInfo({
  service,
  categoryNames,
}: ServiceDetailInfoProps) {
  const { mainCategoryName, subCategoryName, detailCategoryName } =
    categoryNames;

  const serviceForm = findServiceFormName(service.serviceForm);
  const serviceType = findServiceTypeName(service.serviceType);
  const costType = findServiceCostName(service.costType);
  const region = findServiceRegionName(service.serviceRegion);
  const status = findServiceStatusName(service.status);
  const startDate = formatDateToISO(service.startDate);
  const endDate = formatDateToISO(service.endDate);
  const updatedDate = formatDateToISO(service.updatedAt);
  const cost = formatNumberWithCommas(service.cost) || '0';

  return (
    <section className="grid flex-1 w-full grid-cols-4 px-[120px] gap-5 py-12">
      <TextLabelBlock
        label="강의⋅서비스 이름"
        cols={2}
        text={service.serviceName}
      />
      <TextLabelBlock label="강사" cols={2} text={service.instructor} />
      <TextLabelBlock cols={4} label="서비스 설명" text={service.description} />
      <TextLabelBlock label="교육업체" cols={2} text={service.companyName} />

      <TextLabelBlock
        label="서비스 URL"
        cols={2}
        text={
          <a
            className="text-blue-400 hover:text-blue-500"
            href={service.homepageURL}
          >
            {service.homepageURL}
          </a>
        }
      />
      <TextLabelBlock label="서비스 유형" text={serviceType} />
      <TextLabelBlock label="서비스 접근 유형" text={serviceForm} />
      <TextLabelBlock label="비용 유형" text={costType} />
      <TextLabelBlock label="지역" text={region || '선택 없음'} />
      <TextLabelBlock label="모집 시작일" text={startDate} />
      <TextLabelBlock label="모집 종료일" text={endDate} />
      <TextLabelBlock label="최근 업데이트 날짜" text={updatedDate} />
      <TextLabelBlock label="서비스 상태" text={status} />
      <TextLabelBlock
        label="서비스 시간"
        cols={2}
        text={service.serviceTotalTime || '입력 없음'}
      />
      <TextLabelBlock cols={2} label="비용" text={cost} />

      <TextLabelBlock label="대 카테고리" text={mainCategoryName} />
      <TextLabelBlock label="중 카테고리" text={subCategoryName} />
      <TextLabelBlock cols={2} label="소 카테고리" text={detailCategoryName} />
      <TextLabelBlock
        cols={1}
        label="프로필 이미지"
        textClassName="w-fit"
        text={
          <CustomImage
            src={service.profileImage}
            alt="profile"
            className="object-cover w-20 h-20 border rounded-full cursor-pointer border-base-300"
          />
        }
      />
      <TextLabelBlock
        cols={1}
        label="썸네일 이미지"
        textClassName="w-fit"
        text={
          <CustomImage
            src={service.thumbnailImage}
            alt="profile"
            className="h-20 aspect-[650/370] border rounded-md cursor-pointer border-base-300 object-cover"
          />
        }
      />
      <TextLabelBlock
        cols={2}
        label="배너 이미지"
        textClassName="w-fit"
        text={
          <CustomImage
            src={service.bannerImage}
            alt="profile"
            className="w-full  h-20 aspect-[2160/330] border rounded-md cursor-pointer border-base-300 object-cover"
          />
        }
      />
      <TextLabelBlock
        cols={4}
        label="서비스 내용"
        text={<ServiceDetailEditorViewer content={service.serviceContent} />}
      />
    </section>
  );
}
