import { ChevronLeft, ChevronRight } from 'lucide-react';
import { cn } from 'src/lib/utils/className';

interface PaginationButtonUIProps {
  /** 전체 데이터 길이 */
  length?: number;
  /** 페이지 당 데이터 수 */
  limit?: number;
  /** 표시할 페이지 수 */
  pagesToShow?: number;
  /** 현재 페이지 */
  currentPage?: number;

  setPage: (page: number) => void;
}

const PaginationButtonUI = ({
  length = 0,
  limit = 10,
  currentPage = 1,
  pagesToShow = 10,
  setPage,
}: PaginationButtonUIProps) => {
  const totalPages = Math.ceil(length / limit);
  const pageGroup = Math.ceil(currentPage / pagesToShow);

  const startPage = (pageGroup - 1) * pagesToShow + 1;
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  const isFirstPageGroup = pageGroup === 1;
  const isLastPageGroup = pageGroup === Math.ceil(totalPages / pagesToShow);

  const pages = createPages(startPage, endPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPage(pageGroup * pagesToShow + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPage((pageGroup - 2) * pagesToShow + 1);
    }
  };

  return (
    <div className="flex items-center justify-center w-full gap-3">
      {!isFirstPageGroup && (
        <button
          onClick={handlePrevPage}
          className="grid w-8 h-8 place-items-center"
        >
          <ChevronLeft className="w-5 h-5 text-base-400" />
        </button>
      )}

      {pages.map(page => (
        <button
          className={cn(
            'w-8 h-8 text-sm rounded-full text-base-600 font-medium transition-colors',
            page === currentPage && 'bg-primary text-white',
          )}
          key={page}
          onClick={() => setPage(page)}
        >
          {page}
        </button>
      ))}
      {!isLastPageGroup && (
        <button
          onClick={handleNextPage}
          className="grid w-8 h-8 place-items-center"
        >
          <ChevronRight className="w-5 h-5 text-base-400" />
        </button>
      )}
    </div>
  );
};

export { PaginationButtonUI, type PaginationButtonUIProps };

const createPages = (startPage: number, endPage: number) => {
  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }
  return pages;
};
