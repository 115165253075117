import { Link, useLocation, useMatch } from 'react-router-dom';
import { NAVIGATION_ITEM } from 'src/lib/constants/navigation';
import { cn } from 'src/lib/utils/className';
import { isPathMatch } from 'src/lib/utils/common';
import Logo from '../components/common/icons/logo';
import LogoutButton from 'src/components/auth/logout-button';

export default function SideNavigationBar() {
  const { pathname } = useLocation();

  const isLogin = useMatch('/login');

  if (isLogin) return null;

  // FIXME 태그 수정
  return (
    <aside className="flex flex-col px-2 gap-y-[70px] py-[60px]  h-screen w-80 sticky top-0 left-0 shadow-lg">
      <Link
        to="/"
        className="flex flex-col px-8 cursor-pointer text-start gap-y-4"
      >
        <Logo />
      </Link>
      <nav className="flex flex-col gap-y-1">
        {NAVIGATION_ITEM.map(({ href, path, menuName, icon: Icon }, index) => {
          const isMatch = isPathMatch(path, pathname);
          return (
            <Link
              key={index}
              to={href}
              className={cn('w-full', isMatch && 'pointer-events-none')}
            >
              <button
                className={cn(
                  `h-14 text-start pl-8 flex items-center gap-x-3 transition-colors  font-medium w-full text-base-800  hover:bg-primary-100 rounded-md `,
                  isMatch && `bg-primary  text-white`,
                )}
              >
                <Icon className="w-5 h-5" />
                <div>{menuName}</div>
              </button>
            </Link>
          );
        })}
      </nav>

      <LogoutButton />
    </aside>
  );
}
