import { useParams } from 'react-router-dom';
import ServiceDetailContainer from 'src/components/service/detail/service-detail-container';
import ServiceQuery from 'src/services/queries/service';

export const ServiceDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = ServiceQuery.useGetServiceDetail(id!);

  return (
    <div className="w-full h-full min-h-screen bg-white rounded-md shadow-lg">
      {data && <ServiceDetailContainer service={data} />}
    </div>
  );
};
