import { GraduationCap, LucideIcon, Building2, Layers3 } from 'lucide-react';

export const ITEM_NAV = [
  { name: '강의 · 서비스명', basisValue: 15 },
  { name: '서비스 유형', basisValue: 11.25 },
  { name: '대 카테고리', basisValue: 11.25 },
  { name: '중 카테고리', basisValue: 11.25 },
  { name: '소 카테고리', basisValue: 11.25 },
  { name: '비용', basisValue: 8 },
  { name: '모집종료일', basisValue: 8 },
  { name: 'Status', basisValue: 8 },
  { name: '조회', basisValue: 6 },
];

type NavigationItem = {
  menuName: string;
  path: string;
  href: string;
  icon: LucideIcon;
};
export const NAVIGATION_ITEM: NavigationItem[] = [
  {
    menuName: '강의⋅서비스 관리',
    path: '/service*',
    href: '/service',
    icon: GraduationCap,
  },
  {
    menuName: '교육 업체 관리',
    path: '/company*',
    href: '/company',
    icon: Building2,
  },
  {
    menuName: '카테고리 관리',
    path: '/category*',
    href: '/category',
    icon: Layers3,
  },
];

// {
//   menuName: 'Home',
//   href: '/',
// },
// {
//   menuName: '회원 관리',
//   href: '/member',
// },
// {
//   menuName: '신고 관리',
//   href: '/complain/review',
// },
// {
//   menuName: '콘텐츠 관리',
//   href: '/contents/review',
// },
// {
//   menuName: '고객센터 문의',
//   href: '/faq',
// },
// {
//   menuName: '관리자 설정',
//   href: '/setting',
// },
