import { cva } from 'class-variance-authority';
import React from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { cn } from 'src/lib/utils/className';

interface CustomTextAreaProps extends TextareaAutosizeProps {
  className?: string;
  variant?: 'sm' | 'default';
}

const textareaVariants = cva(
  'flex items-center text-base-700 rounded-md bg-slate-100 border w-full border-slate-300 ring-0 outline-none focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-muted-foreground',
  {
    variants: {
      variant: {
        sm: 'text-sm p-2 min-h-10',
        default: 'text-base p-3 min-h-12',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const CustomTextArea = React.forwardRef<
  HTMLTextAreaElement,
  CustomTextAreaProps
>(({ className, variant, ...props }, ref) => {
  return (
    <TextareaAutosize
      className={cn(textareaVariants({ variant }), className)}
      {...props}
      ref={ref}
    />
  );
});

export default CustomTextArea;
