import { Categories, CategoryType } from 'src/lib/types/category';
import { Company } from 'src/lib/types/company';
import { ExtendedService } from 'src/lib/types/service';
import { create } from 'zustand';

/** 모달 타입 */
type ModalType =
  | 'service_detail'
  | 'service_delete'
  | 'company_detail'
  | 'company_create'
  | 'company_edit'
  | 'company_delete'
  | 'company_list'
  | 'category_create'
  | 'category_delete';

/** 모달에 전달할 데이터 */
type ModalData = {
  service?: ExtendedService;
  category?: Categories;
  company?: Company;
  id?: string | number;
  categoryType?: CategoryType;
};

/** 모달에 전달할 액션 */
type ModalAction = {
  onSelectCompany?: (company: Company) => void;
};

type ModalState = {
  isOpen: boolean;
  type: ModalType | null;
  data: ModalData | null;
  action: ModalAction | null;
};

export type ModalOpenProps = {
  type: ModalType;
  data?: ModalData;
  action?: ModalAction;
};

/**
 * 모달을 열 때는 onOpen을 사용하고, 모달을 닫을 때는 onClose를 사용합니다.
 * @onOpen 모달을 열 때 전달할 데이터
 * @onClose 모달을 닫을 때 초기화
 */
type ModalActions = {
  onOpen: ({ type, data, action }: ModalOpenProps) => void;

  onClose: () => void;
};

const useModal = create<ModalState & ModalActions>(set => ({
  isOpen: false,
  type: null,
  data: null,
  action: null,
  onOpen: ({ type, data, action }: ModalOpenProps) =>
    set({ isOpen: true, type, data, action }),
  onClose: () => set({ isOpen: false, type: null, data: null, action: null }),
}));

export default useModal;
