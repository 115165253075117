export type MenuList = {
  pathname: string;
  label: string;
};

// 서비스 상단 네비게이션
export const SERVICE_MENU_LIST = [
  {
    pathname: '/service',
    label: '강의 · 서비스 관리',
  },
  {
    pathname: '/service/create',
    label: '강의 · 서비스 등록',
  },
];

// 서비스 상단 네비게이션
export const CATEGORY_MENU_LIST = [
  {
    pathname: '/category',
    label: '카테고리 관리',
  },
];

// 교육업체 상단 네비게이션
export const COMPANY_MENU_LIST = [
  {
    pathname: '/company',
    label: '교육업체 관리',
  },
];

export const memberMenu = [
  {
    pathname: 'member',
    menuName: '회원 관리',
  },
];

export const complainMenu = [
  {
    pathname: '/complain/review',
    menuName: '후기 신고',
  },
  {
    pathname: '/complain/comment',
    menuName: '댓글 신고',
  },
];

export const contentsMenu = [
  {
    pathname: '/contents/review',
    menuName: '후기 관리',
  },
  {
    pathname: '/contents/comment',
    menuName: '댓글 관리',
  },
];

export const inquiryMenu = [
  {
    pathname: '/inquiry/apply',
    menuName: '미등록 강의⋅서비스 신청',
  },
  {
    pathname: '/inquiry/review',
    menuName: '미등록 후기',
  },
  {
    pathname: '/inquiry/manage',
    menuName: '인증 관리',
  },
  {
    pathname: '/inquiry/request',
    menuName: '정보 수정 요청',
  },
  {
    pathname: '/inquiry/alliance',
    menuName: '제휴 문의',
  },
  {
    pathname: '/inquiry/history',
    menuName: '문의 내역',
  },
];

export const settingMenu = [
  {
    pathname: '/setting',
    menuName: '관리자 설정',
  },
];
