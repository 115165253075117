import { useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';
import 'react-quill/dist/quill.snow.css';
import './service-editor-style.css';
import AWS from 'aws-sdk';

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);

interface EditorProps {
  value: string;
  onChange: (html: string) => void;
}

const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

const Editor = ({ value, onChange }: EditorProps) => {
  const quillRef = useRef<ReactQuill>(null);

  const uploadImage = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const editor = quillRef.current?.getEditor();

      if (!editor) return;

      const file = input.files?.[0];

      if (!file) return;

      const range = editor.getSelection(true);

      try {
        const name = Date.now();

        AWS.config.update({
          region: REGION,
          accessKeyId: ACCESS_KEY,
          secretAccessKey: SECRET_ACCESS_KEY,
        });
        const upload = new AWS.S3.ManagedUpload({
          params: {
            ACL: 'public-read',
            Bucket: 'aliy',
            Key: `upload/${name}`,
            Body: file,
          },
        });

        const IMG_URL = await upload.promise().then((res: any) => res.Location);

        editor.insertEmbed(range.index, 'image', IMG_URL);
      } catch (error) {
        console.error(error);
      }
    });
  };

  const modules = useMemo(
    () => ({
      imageActions: {},
      imageFormats: {},
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          [{ size: ['small', 'medium', 'large', 'huge', false] }],
          [{ color: [] }, { background: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['image'],
          ['clean'],
        ],
        handlers: {
          image: uploadImage,
        },
      },
    }),
    [],
  );

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'background',
    'align',
    'float',
    'height',
    'width',
  ];

  return (
    <div onClick={e => e.stopPropagation()}>
      <ReactQuill
        ref={quillRef}
        value={value || ''}
        onChange={onChange}
        modules={modules}
        formats={formats}
        theme="snow"
      />
    </div>
  );
};

export default Editor;
