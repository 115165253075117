import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from '../../ui/dialog';

import { Button } from 'src/components/ui/button';
import { X } from 'lucide-react';
import CategoryQuery from 'src/services/queries/category';
import { CategoryType } from 'src/lib/types/category';
import useCategory from 'src/hooks/use-category';

interface PropsType {
  type: CategoryType;
  id: number;

  onClose: () => void;
}

const CategoryDeleteModal = ({ type, id, onClose }: PropsType) => {
  const { mutate, isPending } = CategoryQuery.useRemoveCategory();
  const { findDetailCategoryById, findMainCategoryById, findSubCategoryById } =
    useCategory();

  const getCategoryName = () => {
    switch (type) {
      case 'main':
        return findMainCategoryById(id)?.name;
      case 'sub':
        return findSubCategoryById(id)?.name;
      case 'detail':
        return findDetailCategoryById([id])?.[0]?.name;
      default:
        return '';
    }
  };

  const handleDeleteCategory = () => {
    mutate(
      { id, type },
      {
        onSuccess: () => {
          alert('카테고리 삭제가 완료되었습니다.');
          onClose();
        },
        onError: () => {
          alert('카테고리 삭제에 실패하였습니다.');
        },
      },
    );
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="min-w-[600px] max-h-[700px] overflow-auto custom-scrollbar">
        <DialogHeader></DialogHeader>
        <DialogDescription className="flex flex-col items-center w-full py-8 text-base text-center gap-y-8">
          <span className="w-16 h-16 border-primary rounded-full border-[5px] flex items-center justify-center">
            <X className="w-10 h-10 text-primary" />
          </span>
          <span className="flex flex-col text-xl text-base-700 gap-y-2 ">
            <b>{getCategoryName()}</b>
            카테고리를 삭제하시겠습니까?
          </span>
        </DialogDescription>
        <DialogFooter className="flex gap-x-1">
          <Button variant="outline" onClick={onClose}>
            취소하기
          </Button>
          <Button
            isLoading={isPending}
            disabled={isPending}
            onClick={handleDeleteCategory}
          >
            삭제하기
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryDeleteModal;
