import { Reorder } from 'framer-motion';
import { Category, CategoryType } from 'src/lib/types/category';
import CategoryItem from './category-item';

import CategoryQuery from 'src/services/queries/category';
import { useState } from 'react';
import BeforeReorderingButtons from './before-reordering-buttons';
import AfterReorderingButtons from './after-reordering-buttons';
import useModal from 'src/hooks/use-modal';
import { cn } from 'src/lib/utils/className';

type PropsType = {
  type: CategoryType;
  list: Category[];
  parentId: number | null;
  selectedCategory: Category | null;
  selectCategory: (type: CategoryType, category: Category) => void;
  onReorder: (reorderList: Category[]) => void;
  onResetOrder: () => void;
};

const CategoryList = ({
  type,
  list,
  parentId,
  selectedCategory,
  selectCategory,
  onReorder,
  onResetOrder,
}: PropsType) => {
  const { onOpen } = useModal();

  const { mutate: reorderCategory } = CategoryQuery.useUpdateCategorySorting();
  const { mutate: updateCategoryName } = CategoryQuery.useUpdateCategoryName();

  const [isReordering, setIsReordering] = useState<boolean>(false);

  /** 정렬 취소하기 */
  const handleCancelReorder = () => {
    onResetOrder();
    setIsReordering(false);
  };

  /** 정렬 저장하기 */
  const handleSaveReorder = () => {
    reorderCategory({ type, list });
    setIsReordering(false);
  };

  /** 정렬 모드 ON */
  const handleReorderStart = () => {
    setIsReordering(true);
  };

  /** 카테고리 이름 수정 */
  const handleUpdateCategoryName = (id: number, name: string) => {
    updateCategoryName({ type, id, name });
  };

  /** 카테고리 삭제 모달*/
  const handleDeleteCategory = (id: number) => {
    onOpen({ type: 'category_delete', data: { categoryType: type, id } });
  };

  /** 카테고리 추가 모달 */
  const handleCreateCategory = () => {
    onOpen({
      type: 'category_create',
      data: { categoryType: type, id: parentId! },
    });
  };

  const disabled =
    (type === CategoryType.SUB || type === CategoryType.DETAIL) &&
    parentId === null;

  return (
    <div className="flex flex-col gap-y-2 min-w-[400px] h-full ">
      <h3 className="text-center text-[20px] w-full bg-white text-base-700 border-base-300 border py-2 rounded-md">
        {title[type]}
      </h3>

      <div
        className={cn(
          'flex flex-col px-4 py-6 bg-white border rounded-md shadow-md border-base-200 gap-y-5',
          isReordering && 'bg-primary-light',
        )}
      >
        <Reorder.Group axis="y" values={list} onReorder={onReorder}>
          <div className="flex flex-col flex-1 gap-y-2 w-full h-[500px] overflow-auto custom-scrollbar ">
            {list.map(item => (
              <Reorder.Item
                id={String(item.id)}
                key={item.id}
                value={item}
                dragListener={isReordering}
              >
                <CategoryItem
                  category={item}
                  isSelected={item.id === selectedCategory?.id}
                  type={type}
                  disabled={isReordering}
                  onSelectCategory={selectCategory}
                  onUpdateCategory={handleUpdateCategoryName}
                  onDeleteCategory={handleDeleteCategory}
                />
              </Reorder.Item>
            ))}
          </div>
        </Reorder.Group>

        {isReordering && (
          <AfterReorderingButtons
            onReorder={handleSaveReorder}
            onResetOrder={handleCancelReorder}
          />
        )}

        {!isReordering && (
          <BeforeReorderingButtons
            disabled={disabled}
            onReorderStart={handleReorderStart}
            onAddCategory={handleCreateCategory}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryList;

const title = {
  main: '대 카테고리',
  sub: '중 카테고리',
  detail: '소 카테고리',
};
