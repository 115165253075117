import { format } from 'date-fns';
import { OptionType } from '../types/category';
import URLPattern from 'url-pattern';
import qs from 'query-string';

export function isPathMatch(pathname: string, currentPath: string) {
  const pattern = new URLPattern(pathname);
  return pattern.match(currentPath);
}

export function isPathInclude(pathname: string, currentPath: string) {
  return pathname.split('/').includes(currentPath);
}

export function parseISODate(date: string | undefined | null) {
  if (!date) return;
  return new Date(date);
}

export function formatDateToISO(date: string | undefined | null | Date) {
  try {
    if (!date) return '';
    return format(new Date(date), 'yyyy-MM-dd');
  } catch (e) {
    return '';
  }
}

// 1000 -> 1,000
export function formatNumberWithCommas(number: number | undefined) {
  if (!number) return '0';
  return number.toLocaleString();
}

// 1,000 -> 1000
export function parseNumberFromString(str: string | undefined) {
  if (str === undefined) return 0;
  return parseInt(str.replace(/,/g, ''));
}

export function extractLastSegment(url: string) {
  const segments = url.split('/');
  return segments[segments.length - 1];
}

export const makeCategoryOption = (
  obj: { name: string; id: number } | undefined,
): OptionType => {
  if (!obj) return { label: '', value: '' };
  return {
    label: obj.name,
    value: String(obj.id),
  };
};

// 객체에서 빈 값 제거
export function removeEmptyValues(
  obj: Record<string, any>,
): Record<string, any> {
  const newObj: Record<string, any> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== '') {
      newObj[key] = value;
    }
  }
  return newObj;
}

// 객체에서 특정 키 제거
export function removeKeys<T>(
  obj: { [key: string]: T },
  keysToRemove: string[],
) {
  const result = { ...obj };
  keysToRemove.forEach(key => {
    delete result[key];
  });
  return result;
}

export function getQueryObject(searchParams: URLSearchParams) {
  return Object.fromEntries(searchParams.entries());
}

/** querystring과 url을 조합 */
export function createUrl(
  url: string,
  queryObject: Record<string, any>,
  skipNull = true,
) {
  return qs.stringifyUrl(
    {
      url,
      query: queryObject,
    },
    {
      skipNull,
    },
  );
}

/**  텍스트가 n자 이상인지 확인하는 함수 */
export function isTextLengthAtLeast(text: string, length: number) {
  return text.length >= length;
}

/**
 * 텍스트가 특정 길이 이상이면 나머지를 "..."으로 처리하는 함수
 * @param {string} text - 확인할 텍스트
 * @param {number} length - 기준 길이
 * @return {string} - 처리된 텍스트
 */
export function truncateText(text: string, length: number): string {
  if (!isTextLengthAtLeast(text, length)) {
    return text;
  }
  return text.substring(0, length) + '...';
}

/** 줄수로 글자 수 제한 */
export const truncateTextByLines = (text: string, maxLines: number) => {
  const lines = text.split('\n');
  if (lines.length <= maxLines) {
    return { truncatedText: text, isTruncated: false };
  }

  let truncatedText = '';
  for (let i = 0; i < maxLines; i++) {
    truncatedText += lines[i] + '\n';
  }
  truncatedText = truncatedText.trimEnd();
  return { truncatedText, isTruncated: true };
};
