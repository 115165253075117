import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table';
import { ExtendedService } from 'src/lib/types/service';
import { formatDateToISO, formatNumberWithCommas } from 'src/lib/utils/common';
import CustomImage from '../common/custom-image';
import {
  findServiceStatusName,
  findServiceTypeName,
} from 'src/lib/constants/service';
import { Badge } from '../ui/badge';
import { getCategoryNamesByMapper } from 'src/lib/utils/category';
import useCategory from 'src/hooks/use-category';
import { SERVICE_TABLE_HEADER } from 'src/lib/constants/table';
import useModal from 'src/hooks/use-modal';

interface ServiceTableProps {
  serviceList: ExtendedService[];
  rowsPerPage?: number;
}

export default function ServiceTable({
  serviceList,
  rowsPerPage = 10,
}: ServiceTableProps) {
  const { onOpen } = useModal();
  const { category } = useCategory();
  const emptyRows = rowsPerPage - serviceList.length;

  const handleRowClick = (service: ExtendedService) => {
    onOpen({
      type: 'service_detail',
      data: {
        service: service,
        category,
      },
    });
  };

  return (
    <Table className="bg-white rounded-lg min-w-[1200px] w-full max-w-[1420px]">
      <TableHeader>
        <TableRow>
          {SERVICE_TABLE_HEADER.map(item => (
            <TableHead
              key={item.label}
              style={{ width: item.width, textAlign: item.position }}
            >
              {item.label}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {serviceList.map(service => {
          const { mainCategoryName, subCategoryName, detailCategoryName } =
            getCategoryNamesByMapper(category, service.categoryMapper);

          return (
            <TableRow
              key={service.id}
              onClick={() => handleRowClick(service)}
              className="cursor-pointer"
            >
              <TableCell className="w-[70px] text-center">
                <CustomImage
                  src={service.profileImage}
                  className="w-8 h-8 rounded-full"
                  alt="profile"
                />
              </TableCell>
              <TableCell className="flex items-center justify-center px-2 text-center h-[68px]">
                {service.serviceName}
              </TableCell>
              <TableCell className="text-center ">
                {findServiceTypeName(service.serviceType)}
              </TableCell>
              <TableCell className="text-center">{mainCategoryName}</TableCell>
              <TableCell className="text-center">{subCategoryName}</TableCell>
              <TableCell className="text-center">
                {detailCategoryName}
              </TableCell>
              <TableCell className="text-center">
                {convertCost(service.cost)}
              </TableCell>
              <TableCell className="text-center">
                {formatDateToISO(service.endDate) || '-'}
              </TableCell>
              <TableCell className="text-center">
                <Badge variant="positive">
                  {findServiceStatusName(service.status)}
                </Badge>
              </TableCell>
            </TableRow>
          );
        })}

        {Array.from({ length: emptyRows }).map((_, index) => (
          <TableRow key={`empty-row-${index}`}>
            <TableCell className="w-[70px] text-center"></TableCell>
            <TableCell className="flex items-center justify-center px-2 text-center h-[68px]"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
            <TableCell className="text-center"></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const convertCost = (cost: number | undefined) => {
  if (!cost) return '무료';
  return formatNumberWithCommas(cost);
};
