import { Trash2 } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import useModal from 'src/hooks/use-modal';

interface ServiceDeleteButtonProps {
  id: number;
}

export default function ServiceDeleteButton({ id }: ServiceDeleteButtonProps) {
  const { onOpen } = useModal();

  const handleOpenDeleteModal = () => {
    onOpen({
      type: 'service_delete',
      data: {
        id: String(id),
      },
    });
  };

  return (
    <Button
      onClick={handleOpenDeleteModal}
      variant="outline"
      className="flex text-lg font-medium  gap-x-2   h-[52px]"
    >
      <Trash2 className="w-5" />
      삭제하기
    </Button>
  );
}
