import { Outlet } from 'react-router-dom';
import MainNavigation from './side-navigation-bar';

interface LayoutPropsType {
  children?: React.ReactNode;
}

const Layout = ({ children }: LayoutPropsType) => {
  return (
    <main className="relative flex w-full h-full layout-container ">
      <MainNavigation />

      <div className="w-full   bg-[#ECEFF8] min-h-screen  overflow-auto custom-scrollbar ">
        <div className="max-w-[1500px]  mx-auto w-full py-8   px-10  h-full ">
          {children || <Outlet />}
        </div>
      </div>
    </main>
  );
};

export default Layout;
