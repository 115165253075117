import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../ui/dialog';
import { ExtendedService } from 'src/lib/types/service';
import { Button } from '../../ui/button';
import { formatDateToISO, formatNumberWithCommas } from 'src/lib/utils/common';
import TextLabelBlock from '../../common/label-block/text-label-block';
import { Categories } from 'src/lib/types/category';
import { getCategoryNamesByMapper } from 'src/lib/utils/category';
import {
  findServiceCostName,
  findServiceTypeName,
} from 'src/lib/constants/service';
import { useQueryClient } from '@tanstack/react-query';

interface PropsType {
  service: ExtendedService;
  category: Categories;
  onNavigate: (path: string) => void;
  onClose: () => void;
}

export const ServiceDetailModal = ({
  service,
  category,
  onNavigate,
  onClose,
}: PropsType) => {
  const queryClient = useQueryClient();

  const { mainCategoryName, subCategoryName, detailCategoryName } =
    getCategoryNamesByMapper(category, service.categoryMapper);

  const handleNavigateDetail = () => {
    queryClient.setQueryData(
      ['service', 'detail', String(service.id)],
      service,
    );
    onNavigate(`/service/${service.id}`);
    onClose();
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="min-w-[600px] max-h-[700px] overflow-auto custom-scrollbar">
        <DialogHeader>
          <DialogTitle className="truncate">{service.serviceName}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <div className="grid grid-cols-2 gap-3 ">
            <TextLabelBlock
              variant="sm"
              label="교육 업체"
              text={service.companyName}
            />
            <TextLabelBlock
              variant="sm"
              label="강사명"
              text={service.instructor}
              textClassName="truncate"
            />
            <TextLabelBlock
              variant="sm"
              label="서비스 유형"
              text={findServiceTypeName(service.serviceType)}
            />
            <TextLabelBlock
              variant="sm"
              label="비용 유형"
              text={findServiceCostName(service.costType)}
            />

            <TextLabelBlock
              variant="sm"
              label="대 카테고리"
              text={mainCategoryName}
            />
            <TextLabelBlock
              variant="sm"
              label="중 카테고리"
              text={subCategoryName}
            />
            <TextLabelBlock
              variant="sm"
              cols={2}
              label="소 카테고리"
              text={detailCategoryName}
            />

            <TextLabelBlock
              variant="sm"
              label="비용"
              text={formatNumberWithCommas(service.cost)}
            />

            <TextLabelBlock
              variant="sm"
              label="서비스 시간"
              textClassName="truncate"
              text={service.serviceTotalTime}
            />

            <TextLabelBlock
              variant="sm"
              label="모집 시작일"
              text={formatDateToISO(service.startDate) || '선택 없음'}
            />
            <TextLabelBlock
              variant="sm"
              label="모집 종료일"
              text={formatDateToISO(service.endDate) || '선택 없음'}
            />

            <TextLabelBlock
              variant="sm"
              cols={2}
              label="서비스 설명"
              text={service.description}
            />
          </div>
        </DialogDescription>
        <DialogFooter>
          <Button onClick={handleNavigateDetail}>상세페이지로 이동</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
