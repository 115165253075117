import { authSwitchButton } from 'src/components/utils/utils';
import SmallArrowDrop from '../../icons/small-arrow-drop';

const { createColumnHelper } = require('@tanstack/react-table');

const columnHelper = createColumnHelper();

export const CertificationManagementTableColumn = (detailModalOpen: any) => [
  // Todo : 필터링작업 진행 해야함(헤더의 화살표 / all Column 동일)
  columnHelper.accessor('check', {
    header: () => (
      <div className="flex justify-center text-[#545D69] text-[14px] font-normal">
        <input type="checkbox" className="basis-[4%]" />
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-[#545D69] text-[14px] font-normal">
        {info.renderValue() || <input type="checkbox" className="basis-[4%]" />}
      </div>
    ),
    size: 58,
  }),
  columnHelper.accessor('certificationImage', {
    header: () => (
      <div className="flex justify-center items-center text-[#343A40] text-[14px] font-normal">
        이미지
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#343A40] text-[14px] font-normal">
        <img src={info.renderValue()} alt="" className="img-style" />
      </div>
    ),
    size: 61,
  }),
  columnHelper.accessor('serviceName', {
    header: () => (
      <div className="flex justify-between pl-[30px] items-center text-[#343A40] text-[14px] font-normal">
        강의⋅서비스 명
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex flex-start pl-[30px] text-center text-[#343A40] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 610,
  }),
  columnHelper.accessor('nickname', {
    header: () => (
      <div className="flex justify-center items-center text-[#343A40] text-[14px] font-normal">
        작성자 닉네임
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#343A40] text-[14px] font-normal">
        {info.renderValue() || '-'}
      </div>
    ),
    size: 124,
  }),
  columnHelper.accessor('status', {
    header: () => (
      <div className="flex justify-center items-center text-[#343A40] text-[14px] font-normal">
        인증 상태
        <div className="ml-[10px] items-center">
          <SmallArrowDrop color={'#EAEAEA'} transform={'rotate(180)'} />
          <SmallArrowDrop color={'#EAEAEA'} />
        </div>
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center text-[#343A40] text-[14px] font-normal">
        {authSwitchButton(info.renderValue()) || '-'}
      </div>
    ),
    size: 107,
  }),
  columnHelper.accessor('cncel_note', {
    header: () => (
      <div className="flex justify-center text-[#343A40] text-[14px] font-normal">
        조회
      </div>
    ),
    cell: (info: any) => (
      <div className="flex justify-center text-center">
        <button
          onClick={() => detailModalOpen(info.row.original)}
          className="text-blue-600 hover:text-blue-800 w-[72px] h-[26px]"
        >
          상세보기
        </button>
      </div>
    ),
    size: 75,
  }),
];
