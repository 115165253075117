export type BaseCategory = {
  id: number;
  name: string;
  position: number;
  status: string;
};

export interface MainCategory extends BaseCategory {}

export interface SubCategory extends BaseCategory {
  mainCategoryId: number;
}

export interface DetailCategory extends BaseCategory {
  subCategoryId: number;
}

export type Categories = {
  mainCategories: MainCategory[];
  subCategories: SubCategory[];
  detailCategories: DetailCategory[];
};

export type CategoryNames = {
  mainCategoryName: string;
  subCategoryName: string;
  detailCategoryName: string;
};

export type Category = MainCategory | SubCategory | DetailCategory;

export type OptionType = {
  label: string;
  value: any;
};

export enum CategoryType {
  MAIN = 'main',
  SUB = 'sub',
  DETAIL = 'detail',
}
