import { LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from 'src/lib/utils/cookie';

export default function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = () => {
    deleteCookie('jwt');
    navigate('/login', { replace: true });
  };

  return (
    <button
      onClick={handleLogout}
      className="flex items-center w-full mt-auto font-medium transition-colors rounded-md pl-9 h-14 text-start gap-x-3 text-base-800 hover:bg-primary-100"
    >
      <LogOut className="w-5 h-5" />
      <div>로그아웃</div>
    </button>
  );
}
