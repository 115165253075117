import * as z from 'zod';
import { FieldErrors, UseFormReturn, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Company } from 'src/lib/types/company';
import useCategory from 'src/hooks/use-category';
import {
  makeCategoryOption,
  parseISODate,
  removeKeys,
} from 'src/lib/utils/common';
import SERVICE_API from 'src/services/api/service';
import { DetailCategory } from 'src/lib/types/category';
import ServiceQuery from 'src/services/queries/service';
import { INITIAL_EDITOR_STATE } from 'src/lib/constants/editor';

const categorySchema = z.object({
  value: z.string().min(1, 'Value is required.'),
  label: z.string().min(1, 'ID is required.'),
});

export const serviceFormSchema = z
  .object({
    serviceName: z
      .string()
      .min(1, { message: '서비스명을 1자 이상 입력해주세요.' }),
    description: z
      .string()
      .min(1, { message: '서비스에 대한 정보를 1자 이상 입력해주세요.' }),
    companyId: z.number().nullable(),
    instructor: z.string().optional(),
    homepageURL: z.string(),
    serviceType: z.string().min(1, { message: '서비스 유형을 선택해주세요.' }),
    serviceForm: z
      .string()
      .min(1, { message: '서비스 접근 유형을 선택해주세요.' }),

    serviceRegion: z
      .string()
      .optional()
      .transform(value => (value === 'none' ? undefined : value)),
    cost: z.number().optional(),
    costType: z.string().min(1, { message: '비용 유형을 선택해주세요.' }),
    recruitmentDate: z.date().optional(),
    startDate: z.date().optional(),
    endDate: z.date().optional(),
    // generation: z.string().optional(),
    serviceTotalTime: z.string(),
    mainCategoryId: categorySchema,
    subCategoryId: categorySchema,
    detailCategoryId: z
      .array(categorySchema)
      .min(1, { message: 'Please select at least one detailed category.' }),
    profileImage: z.union([z.string(), z.instanceof(File)]),
    bannerImage: z.union([z.string(), z.instanceof(File)]),
    thumbnailImage: z.union([z.string(), z.instanceof(File)]),
    status: z.string().min(1),
    serviceContent: z.string(),
  })
  .refine(
    data => {
      if (data.startDate && data.endDate) {
        return data.startDate < data.endDate;
      }
      return true;
    },
    {
      message: '시작일은 종료일보다 빨라야 합니다.',
    },
  )
  .refine(data => data.serviceForm !== 'none', {
    message: '서비스 접근 유형을 선택해주세요.',
  })
  .refine(data => data.serviceType !== 'none', {
    message: '서비스 유형을 선택해주세요.',
  })
  .refine(data => data.costType !== 'none', {
    message: '비용 유형을 선택해주세요.',
  });

export type ServiceSubmitValues = ServiceSubmitValuesBase & {
  mainCategoryId: number;
  subCategoryId: number;
  detailCategoryId: number[];
};

export type ServiceFormValues = z.infer<typeof serviceFormSchema>;

type ServiceSubmitValuesBase = Omit<
  z.infer<typeof serviceFormSchema>,
  'mainCategoryId' | 'subCategoryId' | 'detailCategoryId'
>;

export type UseServiceFormController = () => {
  form: UseFormReturn<ServiceFormValues>;
  companyName: string;
  createLoading: boolean;
  updateLoading: boolean;
  previews: {
    profileImage: string;
    bannerImage: string;
    thumbnailImage: string;
  };
  onSelectCompany: (company: Company) => void;
  onMainCategoryChange: (newValue: any) => void;
  onSubCategoryChange: (newValue: any) => void;
  onDateChange: (
    date: any,
    name: 'recruitmentDate' | 'startDate' | 'endDate',
  ) => void;
  onContentChange: (content: string) => void;

  onFileChange: (
    e: ChangeEvent<HTMLInputElement>,
    name: 'profileImage' | 'bannerImage' | 'thumbnailImage',
  ) => void;
  onAddService: (data: ServiceFormValues) => Promise<void>;
  onUpdateService: (data: ServiceFormValues) => Promise<void>;
  onDeleteService: (serviceId: string) => Promise<void>;
  initService: (serviceId: string) => Promise<void>;
  onError: (error: any) => void;
};

export const serviceInitialValues = {
  serviceName: '',
  description: '',
  instructor: '',
  homepageURL: '',
  serviceType: 'none',
  serviceRegion: 'none',
  serviceForm: 'none', // SERVICE_FORM
  costType: 'none',
  serviceTotalTime: '', // optional?
  mainCategoryId: { value: '', label: '' },
  subCategoryId: { value: '', label: '' },
  detailCategoryId: [],
  profileImage: '',
  bannerImage: '',
  thumbnailImage: '',
  status: 'Applying',
  serviceContent: INITIAL_EDITOR_STATE,
};

const useServiceFormController: UseServiceFormController = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { mutate: createService, isPending: createLoading } =
    ServiceQuery.useCreateService();

  const { mutate: updateService, isPending: updateLoading } =
    ServiceQuery.useUpdateService(id!);

  const form = useForm<z.infer<typeof serviceFormSchema>>({
    resolver: zodResolver(serviceFormSchema),
    defaultValues: serviceInitialValues,
    mode: 'onChange',
  });

  const { findMainCategoryById, findSubCategoryById, findDetailCategoryById } =
    useCategory();

  const [companyName, setCompanyName] = useState('');

  const [previews, setPreviews] = useState({
    profileImage: '',
    bannerImage: '',
    thumbnailImage: '',
  });

  const handleSelectCompany = (company: Company) => {
    setCompanyName(company.name);
    form.setValue('companyId', company.id);
  };

  const onMainCategoryChange = (newValue: any) => {
    form.setValue('mainCategoryId', newValue);
    form.setValue('subCategoryId', { value: '', label: '' });
    form.setValue('detailCategoryId', []);
  };

  const onSubCategoryChange = (newValue: any) => {
    form.setValue('subCategoryId', newValue);
    form.setValue('detailCategoryId', []);
  };

  const onDateChange = (
    date: any,
    name: 'recruitmentDate' | 'startDate' | 'endDate',
  ) => {
    form.setValue(name, date);
  };

  const onContentChange = (content: string) => {
    form.setValue('serviceContent', content);
  };

  const onFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: 'profileImage' | 'bannerImage' | 'thumbnailImage',
  ) => {
    if (!e.target.files) return null;

    const file = e.target.files[0];

    if (file) {
      form.setValue(name, file);
      setPreviews(prev => ({
        ...prev,
        [name]: URL.createObjectURL(file),
      }));
    }
  };

  const onAddService = async (data: z.infer<typeof serviceFormSchema>) => {
    const category = {
      mainCategoryId: Number(data.mainCategoryId.value),
      subCategoryId: Number(data.subCategoryId.value),
      detailCategoryId: data.detailCategoryId.map(item => Number(item.value)),
    };

    const body = { ...data, ...category };

    let payload = body;

    if (!body['serviceRegion']) {
      payload = removeKeys(body, ['serviceRegion']) as ServiceSubmitValues;
    }

    createService(payload, {
      onSuccess: (data: any) => {
        alert('강의 등록에 성공했습니다.');
        console.log(data);
        navigate('/service');
      },
      onError: (error: any) => {
        console.error('[CREATE: SERVICE_ERROR]', error.message);
        alert(error?.message || '강의 · 서비스 등록에 실패했습니다.');
      },
    });
  };

  const onUpdateService = async (data: z.infer<typeof serviceFormSchema>) => {
    const category = {
      mainCategoryId: Number(data.mainCategoryId.value),
      subCategoryId: Number(data.subCategoryId.value),
      detailCategoryId: data.detailCategoryId.map(item => Number(item.value)),
    };

    const body = { ...data, ...category };

    console.log('body', body);

    let payload = body;

    if (!body['serviceRegion']) {
      payload = removeKeys(body, ['serviceRegion']) as ServiceSubmitValues;
    }

    updateService(payload, {
      onSuccess: (data: any) => {
        alert('강의 수정에 성공했습니다.');
        console.log(data);
        navigate(`/service/${id}`);
      },
      onError: (error: any) => {
        console.error('[UPDATE: SERVICE_ERROR]', error.message);
        alert(error?.message || '강의 · 서비스 수정에 실패했습니다.');
      },
    });
  };

  const onDeleteService = async (serviceId: string) => {
    try {
      const isSuccess = await SERVICE_API.deleteService(serviceId);
      if (!isSuccess) throw new Error('강의 · 서비스 삭제에 실패했습니다.');
      navigate('/service');
    } catch (error: any) {
      console.error('[DELETE: SERVICE_ERROR]', error);
      alert(error.message);
    }
  };

  const initService = async (serviceId: string) => {
    try {
      const service = await SERVICE_API.getServiceDetail(serviceId);
      service.serviceRegion = '';

      setCompanyName(service.companyName);

      setPreviews({
        profileImage: service.profileImage,
        bannerImage: service.bannerImage,
        thumbnailImage: service.thumbnailImage,
      });

      const dates = {
        recruitmentDate: parseISODate(service.recruitmentDate),
        startDate: parseISODate(service.startDate),
        endDate: parseISODate(service.endDate),
      };

      const { categoryMapper } = service;

      const detailCategoryIds = categoryMapper.map(
        category => category.detailCategoryId,
      );

      const category = {
        mainCategoryId: makeCategoryOption(
          findMainCategoryById(categoryMapper[0].mainCategoryId),
        ),
        subCategoryId: makeCategoryOption(
          findSubCategoryById(categoryMapper[0].subCategoryId),
        ),
        detailCategoryId: findDetailCategoryById(detailCategoryIds)?.map(
          (item: Pick<DetailCategory, 'id' | 'name'>) =>
            makeCategoryOption(item),
        ),
      };

      form.reset({ ...service, ...dates, ...category });
    } catch (e: any) {
      alert(e.message);
    }
  };

  const onError = (error: FieldErrors) => {
    const errorValues = Object.values(error || {});
    console.log(error);
    if (!errorValues) return;

    const firstError = errorValues[0];
    if (firstError?.message) alert(firstError.message);
  };

  return {
    form,
    previews,
    companyName,
    createLoading,
    updateLoading,
    onSelectCompany: handleSelectCompany,
    onMainCategoryChange,
    onSubCategoryChange,
    onDateChange,
    onContentChange,
    onFileChange,
    onAddService,
    onUpdateService,
    onDeleteService,
    initService,
    onError,
  };
};

export default useServiceFormController;
