import { RefreshCcw } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';

export default function RefreshParamButton() {
  const [, setSearchParams] = useSearchParams();

  const onReset = () => {
    setSearchParams({});
  };
  return (
    <button
      onClick={onReset}
      className="flex items-center justify-center w-10 h-10 bg-white border rounded-md border-base-300 "
    >
      <RefreshCcw className="w-4 h-4 text-base-400" />
    </button>
  );
}
