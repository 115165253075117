import { Button } from '../ui/button';

interface ReorderingButtonsProps {
  onResetOrder: () => void;
  onReorder: () => void;
}

export default function AfterReorderingButtons({
  onResetOrder,
  onReorder,
}: ReorderingButtonsProps) {
  return (
    <div className="grid grid-cols-2 gap-x-2">
      <Button
        onClick={onResetOrder}
        variant="outline"
        className="h-12 text-base"
      >
        취소하기
      </Button>
      <Button onClick={onReorder} className="h-12 text-base">
        수정하기
      </Button>
    </div>
  );
}
