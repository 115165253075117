import { axiosClient } from "../instance";
import {
    CertApprovedReturn, CertificationList,
    CertificationListRes,
    FaqCourseRegist,
    FaqGetService,
    FaqGetServiceDetail,
    FaqGetServiceResponse, InquiryHisotryWithoutUser,
    InquiryResponse, LectureMappingResponse, LectureMappingServiceId,
    PartialInformationUpdateRequest,
    PartnershipInquiryResponse, PartnershipInquiryWithoutUser, PutLectureMappingResponse,
    ServiceRequestResponse,
    UnregisterReviewResponse
} from "../../lib/types/faq";

const getServiceRequest: (
    page: string,
    limit: string,
) => Promise<{
    success: boolean;
    data: FaqGetServiceResponse;
}> = async (page: string, limit: string) => {
    try {
        const res = await axiosClient.get(
            `/service_request?page=${page}&limit=${limit}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const getServiceRequestDetail: (
    id:number,
) => Promise<{
    success: boolean;
    data: FaqGetServiceDetail;
}> = async (id: number) => {
    try {
        const res = await axiosClient.get(
            `/service_request/${id}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const addCourseOrService = async (data: FaqCourseRegist, id:string | undefined) => {
    try {
        const response = await axiosClient.put(`/service_request/${id}`, data);
        return response;
    } catch (e: any) {
        throw new Error(e);
    }
};

// 미등록 후기
const getUnregistReviewList: (
    page: string,
    limit: string,
) => Promise<UnregisterReviewResponse> = async (page: string, limit: string) => {
    try {
        const res = await axiosClient.get(
            `unregister_review?page=${page}&limit=${limit}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

// 미등록후기 - 강의 매핑
const getlectureMapping: (
    page: string,
    limit: string,
    search:string,
) => Promise<LectureMappingResponse> = async (page: string, limit: string,search:string) => {
    try {
        const res = await axiosClient.get(
            `https://dev.aliy.ai/services?page=${page}&limit=${limit}&search=${search}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const putLectureMapping : (
    // companyId
    id:number,
    data:LectureMappingServiceId,
) => Promise<PutLectureMappingResponse> = async (id:number, data:LectureMappingServiceId) => {
    try {

        const res = await axiosClient.put(`https://dev.aliy.ai/unregister_review/${id}`, data);
        return res.data;
    } catch (e) {
        console.error(e);
        return false;
    }
};

// 인증 관리
const getCertificationList: (
    page: string,
    limit: string,
) => Promise<CertificationListRes> = async (page: string, limit: string) => {
    try {
        const res = await axiosClient.get(
            `course_certification?page=${page}&limit=${limit}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const putCertificationApproved: (
    data: {status:string},
    id: number,
) => Promise<CertificationList> = async (data:{status:string}, id: number) => {
    try {
        const res = await axiosClient.put(
            `/course_certification/${id}`,data
        );
        return res.data;
    } catch (e) {
       throw e;
    }
};


// 정보 수정 요청
const getInformationUpdateReq: (
    page: string,
    limit: string,
) => Promise<ServiceRequestResponse> = async (page: string, limit: string) => {
    try {
        const res = await axiosClient.get(
            `modify_service_detail?page=${page}&limit=${limit}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const putInformationUpdateDetail: (
    id: number,
) => Promise<PartialInformationUpdateRequest> = async (id: number) => {
    try {
        const res = await axiosClient.put(
            `/modify_service_detail/${id}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

// 제휴문의
const getPartnerShipInquiryReq: (
    page: string,
    limit: string,
) => Promise<PartnershipInquiryResponse> = async (page: string, limit: string) => {
    try {
        const res = await axiosClient.get(
            `partnership_inquiry?page=${page}&limit=${limit}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const putPartnerShipStatusChange: (
    id:number,
) => Promise<PartnershipInquiryWithoutUser> = async (id:number) => {
    try {
        const res = await axiosClient.put(
            `partnership_inquiry/${id}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

// 문의내역
const getInquiryReq: (
    page: string,
    limit: string,
) => Promise<InquiryResponse> = async (page: string, limit: string) => {
    try {
        const res = await axiosClient.get(
            `inquiries?page=${page}&limit=${limit}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const putInquiryStatusChange: (
    id:number,
) => Promise<InquiryHisotryWithoutUser> = async (id:number) => {
    try {
        const res = await axiosClient.put(
            `inquiries/${id}`,
        );
        return res.data;
    } catch (e) {
        console.log(e);
        return false;
    }
};


// service_request
//
// const getServiceDetail: (serviceId: string) => Promise<Service> = async (
//     serviceId: string,
// ) => {
//     try {
//         const response = await axiosClient.get(`admin/services/${serviceId}`);
//
//         console.log(response.data);
//         return response.data;
//     } catch (e) {
//         console.log(e);
//         throw new Error("서비스를 불러오는데 실패하였습니다.");
//     }
// };
//

//
// const editService = async (serviceId: string, data: ServiceSubmitValues) => {
//     try {
//         console.log("data", data);
//         await axiosFormClient.put(`admin/services/${serviceId}`, data);
//         return true;
//     } catch (e: any) {
//         throw new Error(e);
//     }
// };
//
// const deleteService = async (serviceId: string) => {
//     try {
//         await axiosClient.delete(`admin/services/${serviceId}`);
//         return true;
//     } catch (e) {
//         console.log(e);
//         return false;
//     }
// };

const FAQ_API = {
    // 미등록 강의, 서비스 신청
    getServiceRequest,
    getServiceRequestDetail,
    addCourseOrService,

    // 미등록 후기
    getUnregistReviewList,
    getlectureMapping,
    putLectureMapping,
    // 인증 관리
    getCertificationList,
    putCertificationApproved,

    // 정보 수정 요청
    getInformationUpdateReq,
    putInformationUpdateDetail,

    // 제휴문의
    getPartnerShipInquiryReq,
    putPartnerShipStatusChange,
    // 문의내역
    getInquiryReq,
    putInquiryStatusChange,

};

export default FAQ_API;

