import * as z from 'zod';
import { FieldErrors, useForm } from 'react-hook-form';
import { companyFormSchema, companyInitialValues } from './company-form-schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, useCallback, useState } from 'react';
import { Company } from 'src/lib/types/company';

const useCompanyController = () => {
  const [preview, setPreview] = useState<string>('');

  const form = useForm<z.infer<typeof companyFormSchema>>({
    resolver: zodResolver(companyFormSchema),
    defaultValues: companyInitialValues,
    mode: 'onChange',
  });

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      form.setValue('image', file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const initCompany = useCallback(
    async (company: Company) => {
      form.setValue('name', company.name);
      form.setValue('description', company.description);
      form.setValue('image', company.imageUrl);
      form.setValue('url', company.url);
      setPreview(company.imageUrl);
    },
    [form],
  );

  const onError = (error: FieldErrors) => {
    const errorValues = Object.values(error || {});

    if (!errorValues) return;

    const firstError = errorValues[0];
    if (firstError?.message) alert(firstError.message);
  };

  return {
    form,
    preview,
    onImageChange,
    onError,
    initCompany,
  };
};

export default useCompanyController;
