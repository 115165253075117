import {
  Category,
  CategoryType,
  DetailCategory,
  MainCategory,
  SubCategory,
} from 'src/lib/types/category';
import { axiosClient } from '../instance';

const getAllCategories = async (): Promise<{
  mainCategories: MainCategory[];
  subCategories: SubCategory[];
  detailCategories: DetailCategory[];
}> => {
  try {
    const response = await axiosClient.get('category/list');

    console.log(response.data);
    const { mainList, subList, detailList } = response.data;

    return {
      mainCategories: mainList,
      subCategories: subList,
      detailCategories: detailList,
    };
  } catch (e: any) {
    console.error('ERROR: GET_CATEGORY_LIST', e.message);
    return {
      mainCategories: [],
      subCategories: [],
      detailCategories: [],
    };
  }
};

const addCategory = async (
  type: CategoryType,
  parentId: number | null,
  name: string,
) => {
  try {
    const body = makeCategoryBody(type, parentId, name);
    if (!body) throw new Error('body is not valid');
    await axiosClient.post(`category/${type}`, body);
  } catch (e: any) {
    throw new Error(e);
  }
};

const updateCategoryName = async (
  type: CategoryType,
  id: number,
  name: string,
) => {
  try {
    await axiosClient.put(`category/${type}?${type}_category=${id}`, { name });
  } catch (e: any) {
    throw new Error(e);
  }
};

const updateCategorySorting = async (type: CategoryType, list: Category[]) => {
  try {
    const url = generateCategoryUrl(type, list);

    console.log(list);
    if (!url) throw new Error('url is not valid');
    await axiosClient.put(url, { list });
  } catch (e: any) {
    throw new Error(e);
  }
};

const removeCategory = async (type: CategoryType, id: number) => {
  try {
    await axiosClient.delete(`category?${type}_category=${id}`);
  } catch (e: any) {
    throw new Error(e);
  }
};

const Category_API = {
  getAllCategories,
  addCategory,
  updateCategoryName,
  updateCategorySorting,
  removeCategory,
};

export default Category_API;

const generateCategoryUrl = (type: CategoryType, list: Category[]) => {
  switch (type) {
    case CategoryType.MAIN:
      return 'category';

    case CategoryType.SUB:
      const sub = list[0] as SubCategory;
      return `category?main_category=${sub.mainCategoryId}`;

    case CategoryType.DETAIL:
      const detail = list[0] as DetailCategory;
      return `category?sub_category=${detail.subCategoryId}`;

    default:
      return '';
  }
};

const makeCategoryBody = (
  type: CategoryType,
  parentId: number | null,
  name: string,
) => {
  switch (type) {
    case CategoryType.MAIN:
      return { name };

    case CategoryType.SUB:
      return { name, mainCategoryId: parentId };

    case CategoryType.DETAIL:
      return { name, subCategoryId: parentId };

    default:
      return null;
  }
};
