import { BrowserRouter } from 'react-router-dom';
import Router from './router';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import UserQuery from './services/queries/user';
import ModalProvider from './providers/modal-provider';

function App() {
  UserQuery.usePrefetchUser();
  return (
    <>
      <BrowserRouter>
        <Router />
        <ModalProvider />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default App;
