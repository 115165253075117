'use client';

import { forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import { cn } from 'src/lib/utils/className';
import { Search } from 'lucide-react';

interface SearchBarProps {
  className?: string;
  /** 검색바 종류 default | banner */
  variant?: 'default' | 'banner';
  /** 플레이스 홀더 */
  placeholder?: string;
  onSubmit: () => void;
}

const inputVariants = cva(
  'ring-0 placeholder-gray-400 focus:outline-none border rounded-lg  text-sm',
  {
    variants: {
      variant: {
        default:
          'bg-base-100 border-base-200 text-base-800  lg:max-w-[420px] w-full h-10 pl-8 pr-4 bg-white',
        banner:
          'bg-white border-base-300 w-580pxr h-54pxr pl-48pxr pr-16pxr text-base',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  ({ className, variant = 'default', placeholder = '', onSubmit }, ref) => {
    return (
      <form
        className="relative flex items-center group"
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Search className="absolute w-4 h-4 text-base-400 left-3" />

        <input
          className={cn(inputVariants({ variant }), className)}
          ref={ref}
          placeholder={placeholder}
        />
      </form>
    );
  },
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;
