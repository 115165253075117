import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from '../../ui/dialog';
import { Button } from '../../ui/button';
import { Company } from 'src/lib/types/company';
import CustomImage from '../../common/custom-image';
import InputLabelBlock from '../../common/label-block/input-label-block';
import { Input } from '../../ui/input';
import CustomTextArea from '../../common/custom-textarea';
import { ModalOpenProps } from 'src/hooks/use-modal';
import { useEffect } from 'react';
import useCompanyController from '../../company/use-company-controller';
import CompanyQuery from 'src/services/queries/company';
import { CompanyFormValues } from '../../company/company-form-schema';
import ImageButtonLabel from '../../common/image-button-label';

interface PropsType {
  company: Company;
  onOpen: (payload: ModalOpenProps) => void;
  onClose: () => void;
}

export const CompanyEditModal = ({ company, onOpen, onClose }: PropsType) => {
  const { mutate: updateCompany, isPending } = CompanyQuery.useUpdateCompany(
    String(company.id),
  );

  const { form, preview, onError, onImageChange, initCompany } =
    useCompanyController();

  const handleEdit = (data: CompanyFormValues) => {
    updateCompany(data, {
      onSuccess: () => {
        alert('교육 업체 수정이 완료되었습니다.');
        onClose();
      },
      onError: () => {
        alert('교육 업체 수정에 실패하였습니다.');
      },
    });
  };

  useEffect(() => {
    initCompany(company);
  }, [initCompany, company]);

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="min-w-[600px] max-h-[700px] overflow-auto custom-scrollbar">
        <DialogHeader></DialogHeader>
        <form
          className="flex flex-col gap-y-10"
          onSubmit={form.handleSubmit(handleEdit, onError)}
        >
          <DialogDescription className="flex flex-col items-center w-full gap-y-12">
            <ImageButtonLabel type="edit">
              <CustomImage
                src={preview}
                alt="profile"
                className="w-[120px] h-[120px] rounded-full border border-base-200"
              />
              <input
                onChange={onImageChange}
                className="hidden"
                type="file"
                accept="image/*"
              />
            </ImageButtonLabel>

            <div className="grid w-full grid-cols-2 gap-3">
              <InputLabelBlock variant="sm" label="교육 업체">
                <Input
                  {...form.register('name')}
                  variant="sm"
                  placeholder="교육 업체 이름을 입력해주세요."
                />
              </InputLabelBlock>

              <InputLabelBlock variant="sm" label="URL">
                <Input
                  {...form.register('url')}
                  variant="sm"
                  placeholder="교육 URL 입력해주세요."
                />
              </InputLabelBlock>

              <InputLabelBlock variant="sm" label="업체 설명" cols={2}>
                <CustomTextArea
                  {...form.register('description')}
                  variant="sm"
                  placeholder="교육 업체에 대한 설명을 입력해주세요."
                />
              </InputLabelBlock>
            </div>
          </DialogDescription>
          <DialogFooter>
            <Button isLoading={isPending} disabled={isPending} type="submit">
              수정하기
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
