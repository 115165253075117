import { JSX, useState } from 'react';
// import UnregistCourseServiceReq from "../components/faq/UnregistCourseServiceReq";
// import UnregistReviews from '../components/faq/UnregistReviews';
import CertificationManagement from '../components/faq/CertificationManagement';
import InformationUpdateReq from '../components/faq/InformationUpdateReq';
// import PartnershipInquiry from "../components/faq/PartnershipInquiry";
import InquiryHistory from '../components/faq/InquiryHistory';

const FaqMenuList = [
  {
    id: 0,
    title: '미등록 강의⋅서비스 신청',
  },
  {
    id: 1,
    title: '미등록 후기',
  },
  {
    id: 2,
    title: '인증 관리',
  },
  {
    id: 3,
    title: '정보 수정 요청',
  },
  {
    id: 4,
    title: '제휴 문의',
  },
  {
    id: 5,
    title: '문의 내역',
  },
];
const Faq = () => {
  const [activeId, setActiveId] = useState(0);

  const ToFaqMenuPage = ({ activeId }: { activeId: number }): JSX.Element => {
    switch (activeId) {
      // case 0:
      // return <UnregistCourseServiceReq />;
      // case 1:
      //   return <UnregistReviews />;
      case 2:
        return <CertificationManagement />;
      case 3:
        return <InformationUpdateReq />;
      // case 4:
      // return <PartnershipInquiry />;
      case 5:
        return <InquiryHistory />;
      default:
        return <div>none</div>;
    }
  };

  return (
    <>
      <nav className="flex w-[1160px] justify-between items-center h-[70px] px-[30px] bg-gray-3">
        <div className="flex items-center gap-x-[10px]">
          {FaqMenuList.map((item, idx) => (
            <div
              key={item.id}
              className={`text-[18px] p-[20px] text-gray-5 cursor-pointer ${
                activeId === item.id
                  ? 'font-bold border-b-4 border-solid border-primary'
                  : ''
              }`}
              onClick={() => setActiveId(item.id)}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className="flex items-center gap-x-[13px] px-[20px]">
          <div className="flex flex-col gap-y-[4px]">
            <div className="flex items-center gap-x-[4px]">
              <div className="text-[12px] font-bold">한세준님</div>
              <div className="text-[12px] font-bold px-[12px] py-[5px] rounded-[40px] text-white bg-primary">
                관리자
              </div>
            </div>
            <div className="text-[12px] text-end">한세준</div>
          </div>
        </div>
      </nav>
      <ToFaqMenuPage activeId={activeId} />
    </>
  );
};

export default Faq;
