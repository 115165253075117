import AuthForm from 'src/components/auth/login-form';

const LoginPage = () => {
  return (
    <main className="flex items-center justify-center w-screen h-screen bg-primary-light">
      <section className="flex flex-col gap-y-[43px] border rounded-md p-10 bg-white shadow-lg  border-base-100">
        <div className="flex flex-col text-center gap-y-2">
          <h1 className="text-[32px] font-bold">ADMIN 로그인</h1>
          <p className="text-base-600">관리자 계정으로 로그인 해주세요</p>
        </div>
        <AuthForm />
      </section>
    </main>
  );
};

export default LoginPage;
