import { Check, X } from 'lucide-react';
import { FormEvent, useEffect, useRef } from 'react';
import { Category } from 'src/lib/types/category';

type PropsType = {
  category: Category;
  onUpdateCategory: (id: number, name: string) => void;
  onClose: () => void;
};

const CategoryForm = ({ category, onUpdateCategory, onClose }: PropsType) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const value = inputRef.current?.value;
    if (!value) return alert('카테고리 이름을 입력해주세요.');

    onUpdateCategory(category.id, value);
    onClose();
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = category.name;
    }
  }, [category.name]);

  return (
    <form onSubmit={onSubmit} className="flex w-full gap-x-4">
      <input
        ref={inputRef}
        type="text"
        className="w-full px-2 border rounded-md text-base-800 border-base-300"
      />
      <div className="flex items-center gap-x-2">
        <button
          type="submit"
          className="p-2 text-white transition border border-transparent rounded-md bg-primary/90 hover:bg-primary/80 "
        >
          <Check className="w-4 h-4 " />
        </button>
        <button
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
          className="p-2 transition bg-white border rounded-md text-base-400 border-base-300 hover:bg-base-200 bg-base-200 "
        >
          <X className="w-4 h-4" />
        </button>
      </div>
    </form>
  );
};

export default CategoryForm;
