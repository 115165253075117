import { Pencil } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';

interface ServiceEditButtonProps {
  id: number;
}

export default function ServiceEditButton({ id }: ServiceEditButtonProps) {
  const navigate = useNavigate();

  const handleNavigateToEdit = () => {
    navigate(`/service/edit/${id}`);
  };

  return (
    <Button
      onClick={handleNavigateToEdit}
      className="flex text-lg font-medium hover:bg-base-800 text-white hover:opacity-90  bg-base-800 gap-x-2   h-[52px]"
    >
      <Pencil className="w-5" />
      수정하기
    </Button>
  );
}
