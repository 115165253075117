import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getQueryObject } from 'src/lib/utils/common';
import SERVICE_API from '../api/service';

import { injectedCategoryIdInService } from 'src/lib/utils/service';
import { ServiceSubmitValues } from 'src/components/service/use-service-form-controller';

const useGetServiceList = (searchParams: URLSearchParams) => {
  const queryObject = getQueryObject(searchParams);
  const page = queryObject.page ?? 1;
  const limit = 12;

  return useQuery({
    queryKey: ['service', queryObject],
    queryFn: () => SERVICE_API.getServices({ ...queryObject, page, limit }),
    select: data => {
      return {
        meta: data.meta,
        serviceList: data.result.map(service =>
          injectedCategoryIdInService(service),
        ),
      };
    },
    placeholderData: previous => {
      return previous;
    },
    staleTime: 1000 * 60 * 5,
  });
};

const useGetServiceDetail = (serviceId: string) => {
  return useQuery({
    queryKey: ['service', 'detail', serviceId],
    queryFn: () => SERVICE_API.getServiceDetail(serviceId),
    staleTime: 1000 * 60 * 5,
    select: data => injectedCategoryIdInService(data),
  });
};

const useCreateService = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['service'],
    mutationFn: (data: ServiceSubmitValues) => SERVICE_API.addService(data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['service'],
      });
    },
  });
};

const useUpdateService = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['service'],
    mutationFn: (data: ServiceSubmitValues) =>
      SERVICE_API.editService(id, data),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['service'],
      });
    },
  });
};

const useRemoveService = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['service'],
    mutationFn: () => SERVICE_API.deleteService(id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['service'],
      });
    },
  });
};

const ServiceQuery = {
  useGetServiceList,
  useGetServiceDetail,
  useCreateService,
  useUpdateService,
  useRemoveService,
};

export default ServiceQuery;
