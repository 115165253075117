import { Link, Outlet, useLocation } from 'react-router-dom';
import { MenuList } from 'src/lib/constants/navigation-sub';
import { isPathMatch } from 'src/lib/utils/common';
import { cn } from 'src/lib/utils/className';

type PropsType = {
  menuList: MenuList[];
};

const SubLayout = ({ menuList }: PropsType) => {
  const { pathname } = useLocation();
  return (
    <main className="flex flex-col w-full h-full gap-y-8 ">
      <nav className="w-full">
        <ul className="flex items-center gap-x-6 ">
          {menuList.map(item => {
            const isMatch = isPathMatch(item.pathname, pathname);
            return (
              <li key={item.pathname}>
                <Link
                  to={item.pathname}
                  className={cn(
                    'relative flex items-center h-12 font-medium text-lg  text-base-400 hover:text-base-500',
                    isMatch && 'text-primary pointer-events-none',
                  )}
                >
                  {item.label}
                  {isMatch && (
                    <span className="absolute bottom-0 left-0 w-full h-1 rounded-sm bg-primary" />
                  )}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>

      <Outlet />
    </main>
  );
};

export default SubLayout;
