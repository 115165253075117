import DashBoardItem from 'src/components/home/dashboard-item';
import { homeCardItem } from 'src/lib/constants/home';

export default function HomePage() {
  return (
    <div className="flex flex-col gap-y-[55px] h-full w-full py-[50px]">
      <div className="text-[40px] font-bold text-base-800">대시 보드</div>
      <ul className="flex flex-wrap flex-1 w-full h-full gap-14">
        {homeCardItem.map((item, index) => {
          return <DashBoardItem key={index} item={item} />;
        })}
      </ul>
    </div>
  );
}
