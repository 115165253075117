import { useState } from 'react';

import CustomImage from 'src/components/common/custom-image';
import RatingStars from 'src/components/common/rating-stars';

import { findServiceTypeName } from 'src/lib/constants/service';
import { ExtendedService } from 'src/lib/types/service';
import { truncateTextByLines } from 'src/lib/utils/common';
import ServiceDeleteButton from './service-detail-header-delete';
import ServiceEditButton from './service-detail-header-edit';

interface ServiceDetailHeaderProps {
  service: ExtendedService;
}

export default function ServiceDetailHeader({
  service,
}: ServiceDetailHeaderProps) {
  const [isSpread, setIsSpread] = useState(false);

  const handleSpreadDescription = () => {
    setIsSpread(true);
  };

  const handleFoldDescription = () => {
    setIsSpread(false);
  };

  /** 강의 설명이 n자 이상인지 확인*/
  const { truncatedText, isTruncated } = truncateTextByLines(
    service.description,
    2,
  );

  return (
    <section className="w-full min-h-[330px] h-fit bg-base-50 px-[120px] py-[64px] text-base-800 flex flex-col gap-y-8">
      <div className="h-[120px] flex justify-between items-center">
        <div className="flex gap-x-5">
          <CustomImage
            src={service.profileImage}
            className="bg-base-200 w-[120px] h-[120px] rounded-lg"
            alt="profile"
          />
          <div className="flex flex-col">
            <span className="bg-[#1FD7D0] text-white text-[12px] px-[6px] py-1 rounded-[2px] w-fit mb-[10px]">
              {findServiceTypeName(service.serviceType)}
            </span>
            <div className="flex flex-col ">
              <span className="text-lg font-medium ">
                {service.companyName}
              </span>
              <h1 className="text-[24px] font-bold ">{service.serviceName}</h1>
            </div>
            <div className="flex items-center gap-x-1">
              <RatingStars rating={service.avgStarPoint} />
              <div className="flex items-center gap-x-[2px]">
                <span className="font-bold">{service.avgStarPoint}</span>
                <span className="text-[12px] text-base-400 font-medium">
                  (-)
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          <ServiceEditButton id={service.id} />
          <ServiceDeleteButton id={service.id} />
        </div>
      </div>
      <div className="h-full text-sm font-medium break-all whitespace-pre-line text-base-600 ">
        {isSpread ? service.description : truncatedText + '...'}
        {isTruncated && !isSpread && (
          <button
            onClick={handleSpreadDescription}
            className="ml-2 transition-colors text-base-400 hover:text-base-300"
          >
            더보기
          </button>
        )}
        {isSpread && (
          <button
            onClick={handleFoldDescription}
            className="ml-2 transition-colors text-base-400 hover:text-base-300"
          >
            접기
          </button>
        )}
      </div>
    </section>
  );
}
