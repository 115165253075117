import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '../../ui/dialog';
import { Button } from '../../ui/button';

import { useRef } from 'react';
import CategoryQuery from 'src/services/queries/category';
import { CategoryType } from 'src/lib/types/category';
import { Input } from 'src/components/ui/input';
import InputLabelBlock from 'src/components/common/label-block/input-label-block';

interface PropsType {
  type: CategoryType;
  parentId: number;
  onClose: () => void;
}

export const CategoryCreateModal = ({
  type,
  parentId,

  onClose,
}: PropsType) => {
  const { mutate: addCategory, isPending } = CategoryQuery.useAddCategory();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCreateCategory = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const value = inputRef.current?.value;
    if (!value) return;

    addCategory(
      { type, parentId, name: value },
      {
        onSuccess: () => onClose(),
      },
    );
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="min-w-[600px] max-h-[700px] overflow-auto custom-scrollbar">
        <DialogHeader></DialogHeader>
        <form
          className="flex flex-col gap-y-10"
          onSubmit={handleCreateCategory}
        >
          <div className="flex flex-col items-center w-full text-base-800 gap-y-12">
            <span className="text-xl font-semibold ">카테고리 생성</span>
            <InputLabelBlock label="카테고리 이름">
              <Input
                ref={inputRef}
                type="text"
                className="w-[400px]"
                placeholder="카테고리 이름을 입력해주세요."
              />
            </InputLabelBlock>
          </div>
          <DialogFooter>
            <Button isLoading={isPending} disabled={isPending} type="submit">
              추가하기
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
