export const homeCardItem = [
  {
    title: '회원 관리',
    subTitle: '오늘 새로운 회원',
    curNum: 12,
    maxNum: 90,
  },
  {
    title: '강의⋅서비스 관리',
    subTitle: '강의 서비스 요청 미해결',
    curNum: 12,
    maxNum: 90,
  },
  {
    title: '콘텐츠 관리',
    subTitle: '오늘 새로운 후기',
    curNum: 12,
    maxNum: 90,
  },
  {
    title: '콘텐츠 관리',
    subTitle: '인증 요청 미해결',
    curNum: 12,
    maxNum: 90,
  },
];
