import { useNavigate } from 'react-router-dom';
import AUTH_API from 'src/services/api/auth';

import { useQueryClient } from '@tanstack/react-query';
import { Button } from '../ui/button';
import z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '../ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';

const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, '비밀번호를 입력해주세요'),
});

export default function AuthForm() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
  });

  const isLoading = form.formState.isSubmitting;

  const onSubmit = async (data: z.infer<typeof LoginSchema>) => {
    try {
      const userData = await AUTH_API.login(data);

      queryClient.setQueryData(['user'], userData);

      navigate('/');
    } catch (err: any) {
      console.error(err);
      alert('로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.');
    }
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col max-w-full w-[480px] gap-y-6 "
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-base">이메일</FormLabel>
              <FormControl>
                <Input
                  placeholder="이메일을 입력해주세요"
                  className="w-full"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-base ">비밀번호</FormLabel>
              <FormControl>
                <Input
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  className="w-full"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          type="submit"
          disabled={isLoading}
          isLoading={isLoading}
          className="text-lg h-14 bg-primary"
        >
          로그인
        </Button>
      </form>
    </Form>
  );
}
