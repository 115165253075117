import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Company_API from '../api/company';
import { getQueryObject } from 'src/lib/utils/common';

// 회사목록 조회
const useGetCompanyList = (searchParams: URLSearchParams) => {
  const queryObject = getQueryObject(searchParams);
  const page = queryObject.page ?? 1;
  const limit = queryObject.list ?? 12;

  return useQuery({
    queryKey: ['company', queryObject],
    queryFn: () =>
      Company_API.getAllCompanyList({ ...queryObject, page, limit }),

    select: data => {
      return {
        meta: data.meta,
        companyList: data.result.map(company => company),
      };
    },
    placeholderData: previous => {
      return previous;
    },
    staleTime: Infinity,
  });
};

// 회사 상세 조회
const useGetCompany = (id: string) => {
  return useQuery({
    queryKey: ['company', String(id)],
    queryFn: () => Company_API.getCompany(id),
    staleTime: Infinity,
    enabled: !!id,
  });
};

// 회사 추가
const useAddCompany = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['company'],
    mutationFn: (data: any) => Company_API.addCompany(data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
    },
  });
};

// 회사 수정
const useUpdateCompany = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['company', String(id)],
    mutationFn: (data: any) => Company_API.updateCompany(id, data),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
      queryClient.invalidateQueries({
        queryKey: ['company', String(id)],
      });
    },
  });
};

// 회사 삭제
const useRemoveCompany = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['company', String(id)],
    mutationFn: () => Company_API.removeCompany(id),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
      queryClient.removeQueries({
        queryKey: ['company', String(id)],
      });
    },
  });
};

const CompanyQuery = {
  useGetCompanyList,
  useGetCompany,
  useAddCompany,
  useUpdateCompany,
  useRemoveCompany,
};

export default CompanyQuery;
