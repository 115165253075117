import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '../../ui/dialog';

import { Button } from 'src/components/ui/button';
import { X } from 'lucide-react';
import ServiceQuery from 'src/services/queries/service';

interface PropsType {
  id: string;
  onNavigate: (path: string) => void;
  onClose: () => void;
}

const ServiceDeleteModal = ({ id, onNavigate, onClose }: PropsType) => {
  const { mutate: deleteService, isPending } =
    ServiceQuery.useRemoveService(id);

  const handleDeleteCompany = () => {
    deleteService(undefined, {
      onSuccess: () => {
        alert('서비스 삭제가 완료되었습니다.');
        onNavigate('/service');
        onClose();
      },
      onError: () => {
        alert('서비스 삭제에 실패하였습니다.');
      },
    });
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent className="min-w-[600px] max-h-[700px] overflow-auto custom-scrollbar">
        <DialogHeader></DialogHeader>
        <div className="flex flex-col items-center w-full py-8 text-base text-center gap-y-8">
          <div className="w-16 h-16 border-primary rounded-full border-[5px] flex items-center justify-center">
            <X className="w-10 h-10 text-primary" />
          </div>
          <p className="flex flex-col text-lg gap-y-2">
            서비스를 정말로 삭제하시겠습니까?
          </p>
        </div>
        <DialogFooter className="flex gap-x-1">
          <Button
            isLoading={isPending}
            disabled={isPending}
            onClick={handleDeleteCompany}
          >
            삭제하기
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ServiceDeleteModal;
