import { Controller } from 'react-hook-form';
import CustomTextArea from 'src/components/common/custom-textarea';
import InputLabelBlock from 'src/components/common/label-block/input-label-block';
import Select from 'react-select';
import CustomSelect from 'src/components/common/select/select';
import { ko } from 'date-fns/locale';

import { Input } from 'src/components/ui/input';
import { GroupBase, OptionsOrGroups } from 'react-select';
import useServiceFormController from '../use-service-form-controller';
import ReactDatePicker from 'react-datepicker';
import { formatDateToISO, formatNumberWithCommas } from 'src/lib/utils/common';
import {
  SERVICE_COST,
  SERVICE_FORM,
  SERVICE_REGION,
  SERVICE_TYPE,
} from 'src/lib/constants/service';
import useModal from 'src/hooks/use-modal';
import TextLabelBlock from 'src/components/common/label-block/text-label-block';
import Editor from '../service-editor';
import { Button } from 'src/components/ui/button';
import useCategory from 'src/hooks/use-category';
import CustomImage from 'src/components/common/custom-image';
import { useEffect } from 'react';

interface ServiceEditFormProps {
  id: string;
}

export default function ServiceEditForm({ id }: ServiceEditFormProps) {
  const {
    form,
    companyName,
    updateLoading,
    previews,
    onFileChange,
    onSelectCompany,
    onMainCategoryChange,
    onSubCategoryChange,
    onDateChange,
    initService,
    onUpdateService: onSubmit,
    onError,
  } = useServiceFormController();

  const {
    category,
    findSubCategoryListByMainCategoryId,
    findDetailCategoryListBySubCategoryId,
  } = useCategory();

  const { onOpen } = useModal();

  const subCategoryList = findSubCategoryListByMainCategoryId(
    form.watch('mainCategoryId').value,
  );
  const detailCategoryList = findDetailCategoryListBySubCategoryId(
    form.watch('subCategoryId').value,
  );

  const mainCategoryOptions = makeCategoryOptions(category.mainCategories);
  const subCategoryOptions = makeCategoryOptions(subCategoryList);
  const detailCategoryOptions = makeCategoryOptions(detailCategoryList);

  const handleOpenCompanyList = () => {
    onOpen({
      type: 'company_list',
      action: {
        onSelectCompany,
      },
    });
  };

  useEffect(() => {
    initService(id);
  }, [id]);

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit, onError)}
      className="grid flex-1 w-full grid-cols-2 px-[120px] gap-5 py-12 overflow-auto"
    >
      <InputLabelBlock label="서비스 이름 *">
        <Input
          {...form.register('serviceName')}
          placeholder="강의⋅서비스 이름을 입력해주세요."
        />
      </InputLabelBlock>
      <InputLabelBlock label="강사">
        <Input
          {...form.register('instructor')}
          placeholder="강사를 입력해주세요."
        />
      </InputLabelBlock>
      <button
        type="button"
        className="text-start"
        onClick={handleOpenCompanyList}
      >
        <TextLabelBlock
          label="교육업체 *"
          text={
            companyName || (
              <span className="text-base-500">교육업체를 선택해주세요.</span>
            )
          }
        />
      </button>
      <InputLabelBlock label="서비스 URL *">
        <Input
          {...form.register('homepageURL')}
          placeholder="서비스 URL을 입력해주세요."
        />
      </InputLabelBlock>
      <InputLabelBlock label="서비스 설명 *" cols={2}>
        <CustomTextArea
          {...form.register('description')}
          placeholder="서비스를 설명해주세요."
        />
      </InputLabelBlock>

      <div className="grid w-full grid-cols-3 col-span-2 gap-x-4">
        <InputLabelBlock label="비용">
          <Input
            type="number"
            placeholder="비용을 입력해주세요."
            {...form.register('cost', { valueAsNumber: true })}
          />
          {!!form.watch('cost') && (
            <span className="font-normal text-blue-400 text-end -bottom-10">
              {formatNumberWithCommas(form.watch('cost'))}
            </span>
          )}
        </InputLabelBlock>

        <InputLabelBlock label="모집 시작일">
          <ReactDatePicker
            locale={ko}
            dateFormat="yyyy-MM-dd"
            placeholderText="모집 시작일 선택"
            selected={form.watch('startDate')}
            value={formatDateToISO(form.watch('startDate') + '')}
            onChange={(e: any) => onDateChange(e, 'startDate')}
            onKeyDown={e => e.preventDefault()}
            className="w-full h-[52px] pl-[15px] pr-[50px] border text-base-700 border-solid border-base-300 rounded-lg bg-slate-100 cursor-pointer"
          />
        </InputLabelBlock>

        <InputLabelBlock label="모집 종료일">
          <ReactDatePicker
            locale={ko}
            dateFormat="yyyy-MM-dd"
            placeholderText="모집 종료일 선택"
            selected={form.watch('endDate')}
            value={formatDateToISO(form.watch('endDate') + '')}
            onChange={(e: any) => onDateChange(e, 'endDate')}
            onKeyDown={e => e.preventDefault()}
            className="w-full h-[52px] pl-[15px] pr-[50px] border text-base-700 border-solid border-base-300 rounded-lg bg-slate-100 cursor-pointer"
          />
        </InputLabelBlock>
      </div>

      <InputLabelBlock label="카테고리 *" cols={2}>
        <div className="grid items-center w-full grid-cols-[300px_300px_1fr] gap-x-2 h-[52px]">
          <Controller
            name="mainCategoryId"
            rules={{ required: true }}
            control={form.control}
            render={({ field: { value, ...restField } }) => (
              <Select
                {...restField}
                options={mainCategoryOptions}
                value={value && value.value ? value : undefined}
                placeholder="대 카테고리"
                onChange={onMainCategoryChange}
              />
            )}
          />
          <Controller
            name="subCategoryId"
            rules={{ required: true }}
            control={form.control}
            render={({ field: { value, ...restField } }) => (
              <Select
                {...restField}
                key={value && value.value ? value.value : ''}
                options={subCategoryOptions}
                value={value && value.value ? value : undefined}
                placeholder="중 카테고리"
                onChange={onSubCategoryChange}
              />
            )}
          />
          <Controller
            name="detailCategoryId"
            rules={{ required: true }}
            control={form.control}
            render={({ field }) => (
              <Select
                className="min-w-[200px]"
                {...field}
                isMulti
                options={detailCategoryOptions}
                placeholder="소 카테고리"
              />
            )}
          ></Controller>
        </div>
      </InputLabelBlock>

      <div className="grid w-full grid-cols-4 col-span-2 gap-x-4">
        <InputLabelBlock label="비용 유형 *" cols={1}>
          <CustomSelect
            placeholder="비용 유형"
            items={SERVICE_COST}
            value={form.watch('costType')}
            onSelect={value => form.setValue('costType', value)}
          />
        </InputLabelBlock>

        <InputLabelBlock label="서비스 유형 *" cols={1}>
          <CustomSelect
            placeholder="서비스 유형"
            items={SERVICE_TYPE}
            value={form.watch('serviceType')}
            onSelect={value => form.setValue('serviceType', value)}
          />
        </InputLabelBlock>

        <InputLabelBlock label="온 · 오프라인 *" cols={1}>
          <CustomSelect
            placeholder="온 · 오프라인"
            items={SERVICE_FORM}
            value={form.watch('serviceForm')}
            onSelect={value => form.setValue('serviceForm', value)}
          />
        </InputLabelBlock>

        <InputLabelBlock label="지역" cols={1}>
          <CustomSelect
            placeholder="지역"
            items={SERVICE_REGION}
            value={form.watch('serviceRegion') ?? ''}
            onSelect={value => form.setValue('serviceRegion', value)}
          />
        </InputLabelBlock>
      </div>
      <div className="grid w-full grid-cols-2 col-span-2 gap-x-4">
        <InputLabelBlock label="프로필 이미지">
          <label>
            {previews.profileImage && (
              <CustomImage
                src={previews.profileImage}
                alt="profile"
                className="object-cover w-40 h-40 border rounded-full cursor-pointer border-base-300"
              />
            )}
            {!previews.profileImage && (
              <div className="flex items-center justify-center w-40 h-40 border border-dashed rounded-full cursor-pointer bg-slate-50 border-base-300 text-base-400 hover:bg-slate-100">
                240 * 240
              </div>
            )}

            <Input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={e => onFileChange(e, 'profileImage')}
            />
          </label>
        </InputLabelBlock>
        <InputLabelBlock label="썸네일 이미지">
          <label className="w-full h-full">
            {previews.thumbnailImage && (
              <CustomImage
                src={previews.thumbnailImage}
                alt="profile"
                className="h-40 aspect-[650/370] border rounded-md cursor-pointer border-base-300 object-cover"
              />
            )}
            {!previews.thumbnailImage && (
              <div className="flex items-center justify-center max-h-40 aspect-[650/370] border border-dashed rounded-md cursor-pointer bg-slate-50 border-base-300 text-base-400 hover:bg-slate-100">
                650 * 370
              </div>
            )}
            <Input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={e => onFileChange(e, 'thumbnailImage')}
            />
          </label>
        </InputLabelBlock>
      </div>
      <InputLabelBlock label="배너 이미지" cols={2}>
        <label className="w-full h-full ">
          {previews.bannerImage && (
            <CustomImage
              src={previews.bannerImage}
              alt="profile"
              className="w-full aspect-[2160/330] border rounded-md cursor-pointer border-base-300 object-cover"
            />
          )}
          {!previews.bannerImage && (
            <div className="flex items-center justify-center w-full aspect-[2160/330] border border-dashed rounded-md cursor-pointer bg-slate-50 border-base-300 text-base-400 hover:bg-slate-100">
              2160 x 330
            </div>
          )}

          <Input
            type="file"
            accept="image/*"
            className="hidden"
            onChange={e => onFileChange(e, 'bannerImage')}
          />
        </label>
      </InputLabelBlock>

      <InputLabelBlock label="서비스 내용" cols={2}>
        <Editor
          value={form.watch('serviceContent')}
          onChange={html => form.setValue('serviceContent', html)}
        />
      </InputLabelBlock>
      <Button
        isLoading={updateLoading}
        disabled={updateLoading}
        className="h-[52px] w-[200px] text-base ml-auto col-start-2"
      >
        수정하기
      </Button>
    </form>
  );
}

export const makeCategoryOptions = (
  objArr: any[],
):
  | OptionsOrGroups<
      {
        value: string;
        label: string;
      },
      GroupBase<{
        value: string;
        label: string;
      }>
    >
  | undefined => {
  if (!objArr) return undefined;

  return objArr.map(obj => {
    return {
      label: obj.name,
      value: String(obj.id),
    };
  });
};
