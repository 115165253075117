import React from 'react';

interface MasterInputProps {
  placeholder?: string;
  value?: string | number;
  onChange?: React.ChangeEventHandler;
  name?: string;
  isSearch?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  text?: string;
  width?: string;
}

const InputMaster = ({
  placeholder,
  value,
  onChange,
  name,
  isSearch,
  isReadOnly,
  isDisabled,
  text,
  width,
}: MasterInputProps) => {
  const inputPadding = isSearch
    ? 'pl-[34px]'
    : name === 'detailModalInfo'
    ? 'px-[15px]'
    : 'px-[20px]';

  return (
    <div className="relative w-full flex flex-col items-start gap-[13px]">
      {/* {isSearch && (
        <div className="absolute left-[12px] top-1/2 transform -translate-y-1/2 cursor-pointer">
          <IoIosSearch />
        </div>
      )} */}
      {name === 'detailModalInfo' && (
        <div className="font-bold text-[14px] text-[#131314]">{text}</div>
      )}
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        disabled={isDisabled}
        readOnly={isReadOnly}
        className={`${width ? width : 'w-full'}  
                h-[40px] 
                ${inputPadding} 
                bg-[#FCFCFD] rounded-[8px] font-normal text-[14px] text-[#2C3A4B]  border border-solid border-[#EFEFEF] 
                placeholder:text-[#A5ABB3] placeholder:font-normal
                focus:border-[#0064FF] focus:bg-[#F6F9FF] 
                ${
                  isReadOnly
                    ? 'bg-[#EFEFEF] border border-[#EFEFEF] rounded-[8px]'
                    : ''
                } 
                ${
                  isDisabled
                    ? 'bg-[#F6F9FF] border border-[#EFEFEF] rounded-[8px] pl-[20px]'
                    : ''
                }`}
      />
    </div>
  );
};

export default InputMaster;
