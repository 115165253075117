// FIXME :현재는 의미없는 컴포넌트
export default function DashBoardItem({ item }: any) {
  return (
    <li className="flex flex-col bg-white min-w-[280px]  h-fit p-6 gap-y-2 shadow-md  rounded-[10px] border-gray-4 bg-gray-1">
      <div className="flex flex-col gap-y-1">
        <p className="text-xl font-bold ">{item.title}</p>
        <p className="text-base text-base-400">{item.subTitle}</p>
      </div>

      <div className="text-[36px] font-bold flex">
        <p className="text-primary">12&nbsp;</p>/ 90
      </div>
    </li>
  );
}
