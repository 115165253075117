import { Pencil } from 'lucide-react';
import { cn } from 'src/lib/utils/className';

interface PropsType {
  type: 'add' | 'edit';
  className?: string;
  children: React.ReactNode;
}
const ImageButtonLabel = ({ type, className, children }: PropsType) => {
  return (
    <label className={cn('relative', className)}>
      {children}
      {type === 'edit' && (
        <div className="absolute bottom-0 right-0 grid w-8 h-8 text-white transition-colors rounded-full cursor-pointer hover:opacity-90 bg-base-400 place-items-center ">
          <Pencil className="w-4 h-4" />
        </div>
      )}
    </label>
  );
};

export default ImageButtonLabel;
